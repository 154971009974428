import { DeviceData } from '../../../store/LabEquipment';
import stringOrderingAlphabet from '../../../utils/orderStringsAlphabetically';

export type LabEquipmentOrderMap = {
    [key: string]: DeviceData[];
};

const eliminateDeviceDuplicates = (deviceList: DeviceData[]): DeviceData[] => {
    let foundDeviceCodes: { [key: string]: boolean } = {};
    let uniqueDevices: DeviceData[] = [];
    deviceList.forEach((device: DeviceData) => {
        if (!foundDeviceCodes[device.Lab_Instrument_code]) {
            foundDeviceCodes[device.Lab_Instrument_code] = true;
            uniqueDevices.push(device);
        }
    });

    return uniqueDevices;
};

const labEquipmentDataPreparator = function (
    labEquipmentList: DeviceData[],
    targetTestGroup: string
): LabEquipmentOrderMap {
    let filteredLabEquipment: DeviceData[] = labEquipmentList.filter(
        (equipmentData) =>
            equipmentData.Method_Test_Group === targetTestGroup
    );
    let labEquipmentOrderMap: LabEquipmentOrderMap = {};

    filteredLabEquipment.forEach((equipment: DeviceData) => {
        if (!labEquipmentOrderMap[equipment.Robot_Name]) {
            labEquipmentOrderMap[equipment.Robot_Name] = [];
        }

        labEquipmentOrderMap[equipment.Robot_Name].push(equipment);
    });

    let robotNames = Object.keys(labEquipmentOrderMap).sort(
        stringOrderingAlphabet
    );

    robotNames.forEach((robotName: string) => {
        labEquipmentOrderMap[robotName] = eliminateDeviceDuplicates(
            labEquipmentOrderMap[robotName]
        );
        labEquipmentOrderMap[robotName].sort(
            (device1: DeviceData, device2: DeviceData) => {
                return device1.Lab_Instrument_code.localeCompare(
                    device2.Lab_Instrument_code
                );
            }
        );
    });

    return labEquipmentOrderMap;
};

export default labEquipmentDataPreparator;
