export const AppRoutes = {
    Login: '/login',
    EnrollAuthenticator: '/enroll-authenticator',
    AuthorizeViaAuthenticator: '/authorize-authenticator',
    EmailVerification: '/email-verification',
    CustomerSelect: '/customer-select',
    Homepage: '/'
}

//TODO: change for local development to :5001
export const BaseUrl = ""; //"https://localhost:5001/";