import * as React from 'react';
import { connect } from "react-redux";
import { Redirect, Route } from "react-router";
import { AppRoutes } from '../../core/constants';
import { ApplicationState } from "../../store";

function SecondStepProtectedRoute(props: any) {
    const { component: Component, firstStepSuccess, isLoggedIn, ...rest } = props;

    const redirectUrl = AppRoutes.Login;

    return <Route {...rest}
                render={(props) => {
                    if (firstStepSuccess && !isLoggedIn) {
                        return <Component {...props} />
                    } else {
                        return <Redirect to={redirectUrl} />
                    }
                }} />
}

export default connect(
    (state: ApplicationState) => state.authentication,
    {}
)(SecondStepProtectedRoute)