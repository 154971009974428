import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { AppRoutes } from '../../core/constants';
import { ApplicationState } from '../../store';
import { actionCreators, UserData } from '../../store/UserStore';
import { actionCreators as activeUserActionCreators } from '../../store/ActiveUserStore';
import { actionCreators as productsActionCreators } from '../../store/Products';
import authService from '../authorization/AuthorizeService';
import './Login.css';
import banner from "../../assets/images/banner.png";
import logo from "../../assets/images/logo.png";

interface CustomerSelectProps {
    userDetails: UserData[];
    fetchUserData: any;
    setActiveCustomer: any;
    cleanupProducts: () => void;
}

const CustomerSelect = (props: CustomerSelectProps) => {
    let {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const history = useHistory();

    useEffect(() => {
        props.fetchUserData();
    }, []);

    useEffect(() => {
        if (props.userDetails) {
            if (props.userDetails.length === 1) {
                handleSelectedUser(props.userDetails[0]);
            }
        }
    }, [props.userDetails]);

    const onSubmit = async (formData: any) => {
        const selectedUser = props.userDetails.find(
            (u) => u.no === formData.customer
        );
        if (selectedUser) {
            handleSelectedUser(selectedUser);
        }
    };

    const handleSelectedUser = (selectedUser: UserData) => {
        props.setActiveCustomer(selectedUser);
        props.cleanupProducts();
        authService.getUserManager().setActiveCustomer(selectedUser.no);
        history.replace(AppRoutes.Homepage);
    };

    return (
        <>
            <div className='login-header'>
                <img className="logo" src={logo} />
                <img className="banner" src={banner} />
            </div>
            <div className="login-wrapper">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-title-wrapper">
                        <h1> DataQ Analytics</h1>
                        <h3 className="h3 mb-3 fw-normal">
                            Please select a customer
                        </h3>
                    </div>
                    <div className="form-row">
                        <label htmlFor="authCode">Select customer</label>
                        <select
                            id="customer"
                            className="two-factor-select"
                            {...register('customer', { required: true })}
                            defaultValue={props.userDetails[0]?.no}
                        >
                            {props.userDetails?.map((customer) => (
                                <option key={customer.no} value={customer.no}>
                                    {customer.no}
                                </option>
                            ))}
                        </select>
                        {errors.customer && 'Customer is required!'}
                    </div>

                    <div className="form-footer">
                        <input
                            className="w-100 btn btn-primary btn-lg"
                            type="submit"
                            value="Submit"
                        />
                        <p className="mt-5 mb-3 text-muted">&copy; 2022</p>
                    </div>
                </form>
            </div>
        </>
    );
};

const { fetchUserData } = actionCreators;
const { setActiveCustomer } = activeUserActionCreators;
const { cleanupProducts } = productsActionCreators;

const mapStateToProps = (state: ApplicationState) => {
    return {
        userDetails: state.userDetails,
    };
};

export default connect(mapStateToProps, { fetchUserData, setActiveCustomer, cleanupProducts })(
    CustomerSelect as any
);
