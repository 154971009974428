import { ApiService } from "../../../core/api-service";
import { sanitizeProductNumber } from "../../data-entry/utils/sanitizeProductNumber";

export const fetchInhouseBatchIds = async (productNo: string, methodGroup: string) => {
    const apiService = new ApiService();
    const response = await apiService.get(
        `protected/runresult/inhouse-lot-numbers?productNo=${sanitizeProductNumber(productNo)}&methodGroup=${methodGroup}`
    );

    return response;
}

export const fetchManufacturerBatchIds = async (methodNo: string) => {
    const apiService = new ApiService();
    const response = await apiService.get(
        `protected/runresult/manufacturer-lot-numbers?methodNo=${methodNo}`
    );

    return response;
}