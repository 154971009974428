import * as React from 'react';
import { Marker, ProductData } from '../../../store/Products';
import { AccordionBody, AccordionHeader, AccordionItem, ListGroupItem } from 'reactstrap';
import Link from '../../utilitary-components/Link';
import stringOrderingAlphabet from '../../../utils/orderStringsAlphabetically';
import { sanitizeProductName } from './sanitizeProductName';

const createMarkersList = (
    markers: Marker[],
    itemNo: string,
    sourceSection: string
) => {
    const orderedMarkers = markers.sort((marker1: Marker, marker2: Marker) => {
        return stringOrderingAlphabet(marker1.marker_ID_Name, marker2.marker_ID_Name);
    });
    const markersList = orderedMarkers.map((marker: Marker) => {
        let pathReadyItemNo = itemNo.replace(/\//gm, '@');
        let pathReadyMarkerName = marker.marker_ID_Name.replace(/\//gm, '@');
        return (
            <Link
                href={`data-entry/${sourceSection}/run-results-${sourceSection}/${pathReadyItemNo}/${pathReadyMarkerName}`}
                key={marker.marker_ID_Name}
            >
                <li className="list-group-item">{marker.marker_ID_Name}</li>
            </Link>
        );
    });

    return markersList;
};

const createProductsListWithMarkers = (products: ProductData[], sourceSection: string) => {
    const productsList = products.map((product: ProductData) => {
        return (
            <AccordionItem key={product.item_No}>
                <AccordionHeader targetId={product.item_No}>
                    {sanitizeProductName(product.item_Name)}
                </AccordionHeader>
                <AccordionBody accordionId={product.item_No}>
                    <ul className="list-group">
                        {createMarkersList(
                            product.markers,
                            product.item_No,
                            sourceSection
                        )}
                    </ul>
                </AccordionBody>
            </AccordionItem>
        );
    });

    return productsList;
};

export const createSimpleProductsList = (products: ProductData[]) => {
    const productsList = products.map((product: ProductData) => {
        let sourceSection: string = product.method_test_group.toLocaleLowerCase();
        let pathReadyItemNo = product.item_No.replace(/\//gm, '@');
        let conditionalSegment = sourceSection === "grifols" ? `${product.method_group.replace(/\//gm, '@')}/` : "";

        return (
            <ListGroupItem key={product.item_No} className="product-item-card">
                <Link href={`data-entry/viraq/${sourceSection}/run-results-${sourceSection}/${conditionalSegment}${pathReadyItemNo}`}>
                    {sanitizeProductName(product.item_Name)}
                </Link>
            </ListGroupItem>
        );
    });

    return productsList;
};

export default createProductsListWithMarkers;
