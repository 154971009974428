import { AppRoutes, BaseUrl } from './constants';
import axios from 'axios';
import { UserManager } from '../components/authorization/UserManager';

export class ApiService {
    _userManager: UserManager = new UserManager();

    post = (url: string, data: any): Promise<any> => {
        if (!url.startsWith(BaseUrl)) {
            url = BaseUrl + url;
        }
        try {
            return axios.post(url, data, {
                headers: {
                    Authorization: this._userManager.getAuthorization(),
                    'X-CustomerNo': this._userManager.getActiveCustomer() || ''
                },
            });
        } catch (error: any) {
            this.redirectOn401(error);
            throw error;
        }
    };

    get = async (url: string): Promise<any> => {
        if (!url.startsWith(BaseUrl)) {
            url = BaseUrl + url;
        }
        try {
            const response = await axios.get(url, {
                headers: {
                    Authorization: this._userManager.getAuthorization(),
                    'X-CustomerNo': this._userManager.getActiveCustomer() || ''
                },
            });

            return response.data;
        } catch (error: any) {
            this.redirectOn401(error);
        }
    };

    private redirectOn401(error: any) {
        const s = JSON.stringify(error);
        const errObj = JSON.parse(s);
        // strange problem, status is undefined on second read...? had to serialize and deserialize the error obj
        if (errObj.status === 401) {
            console.log('401 ERROR');
            window.location.href = AppRoutes.Login;
        }
    }
}
