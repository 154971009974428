import moment from 'moment';

export const wrapDateStringInMoment = (dateString: string): any => {
    return moment(dateString.split('-').reverse());
};

export const formatDate = (dateString: string): string => {
    const date = wrapDateStringInMoment(dateString).format('DD-MM');
    return date;
};

export const subtractMonths = (date: Date, monthsNo: number): Date => {
    return moment(date).subtract(monthsNo, 'months').toDate();
}

export const subtractDays = (date: Date, daysNo: number): Date => {
    return moment(date).subtract(daysNo, 'days').toDate();    
}

export const addDays = (date: Date, daysNo: number): Date => {   
    return moment(date).add(daysNo, 'days').toDate();    
}

export const getStartOfDay = (date: Date): Date => {
    return moment(date).startOf('day').toDate();
}

export const getDaysInMonth = (date: Date): number => {
    return moment(date).daysInMonth();
}

export const parseDateString = (date: Date) : Date => {
    return new Date(Date.parse(date.toString()));
}

export const isOverlap = (start1: Date, end1: Date, start2: Date, end2: Date): boolean => {
    return !(end2 < start1 || start2 > end1);
}

export const isoFormat = (date: Date) => {    
    const dd = moment(date).format().split('T')[0];
    return dd;
}

export const today = (date = new Date()) : Date => { 
    return new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      );
 }