import * as React from 'react';
import Table from 'rsuite/Table';
import { CachedLotNoData } from '../../../store/run-result-common-types';
import { ProcessedRunResultRow } from '../../../store/RunResults';
import stringOrderingAlphabet from '../../../utils/orderStringsAlphabetically';
import { CellDataConfig } from '../custom-cells/cellUtils';
import { DateCell } from '../custom-cells/DateCell';
import { EditCell } from '../custom-cells/EditCell';
import { LabelsCell } from '../custom-cells/LabelsCell';
import { RunDataCell } from '../custom-cells/RunDataCell';
import { getTableDimensions, STANDARD_CELL_WIDTH, TABLE_TYPE } from './tableSizeUtils';
import { COUNTER_OPERATION } from './types';

export type EditModeToggleCallback = (
    rowId: string,
    isEditModeOn: boolean
) => void;

export type NewLotNoInsertionToggleCallback = (
    rowId: string,
    operation: COUNTER_OPERATION
) => void;

export type TableRowData = ProcessedRunResultRow & {
    isInEditMode: boolean;
    isLastRunOfTheDay: boolean;
    newLotNoInsertionsCount: number;
    id: string;
    toggleEditMode: EditModeToggleCallback;
};

const createDynamicHeaderSection = (
    headerData: string[],
    cellDataConfig: CellDataConfig,
    collectUpdatedDataForRow: any,
    getInhouseBatchOptions: any,
    toggleNewLotNoInsertionForRow: NewLotNoInsertionToggleCallback,
    cachedNewLotNoData: CachedLotNoData | null
): any[] => {
    let headers: any[] = [];
    let sortedHeaderData = headerData.sort(stringOrderingAlphabet);

    sortedHeaderData.forEach((equipmentName: string) => {
        headers.push(
            <Table.Column  width={150} key={equipmentName}>
                <Table.HeaderCell>{equipmentName}</Table.HeaderCell>
                <RunDataCell
                    dataKey={equipmentName}
                    cellDataConfig={cellDataConfig}
                    collectUpdatedDataForRow={collectUpdatedDataForRow}
                    getInhouseBatchOptions={getInhouseBatchOptions}
                    toggleNewLotNoInsertionForRow={toggleNewLotNoInsertionForRow}
                    cachedNewLotNoData={cachedNewLotNoData}
                />
            </Table.Column>
        );
    });
    headers.push(
        <Table.Column  flexGrow={1}>
            <Table.HeaderCell>&nbsp;</Table.HeaderCell>
            <Table.Cell />
        </Table.Column>
    );

    return headers;
};

const renderRowExpanded = () => {
    //used to control the height of the row in edit mode
    return <div className="invisible-expander"></div>;
};

const createTable = (
    headerData: string[],
    data: TableRowData[],
    cellDataConfig: CellDataConfig,
    toggleEditModeForRow: EditModeToggleCallback,
    rowKey: string,
    handleExpanded: (rowId: string) => void,
    expandedRowKeys: any,
    collectUpdatedDataForRow: any,
    getInhouseBatchOptions: any,
    toggleNewLotNoInsertionForRow: NewLotNoInsertionToggleCallback,
    addRunAfter: (uuid: string, rowModelId: string) => void,
    removeRun: (uuid: string) => void,
    cachedNewLotNoData: CachedLotNoData | null
) => {
    const { viewModeRowHeight, editModeRowHeight } =
        getTableDimensions(TABLE_TYPE.SERAQ, cellDataConfig);

    return (
        <Table
            rowHeight={viewModeRowHeight}
            rowKey={rowKey}
            data={data}
            className="run-results-grid"
            shouldUpdateScroll={true}
            autoHeight={true}
            hover={false}
            renderRowExpanded={renderRowExpanded}
            rowExpandedHeight={editModeRowHeight - viewModeRowHeight}
            expandedRowKeys={expandedRowKeys}
        >
            <Table.Column width={180} key="edit_cell" fixed={true}>
                <Table.HeaderCell>&nbsp;</Table.HeaderCell>
                <EditCell
                    toggleEditModeForRow={toggleEditModeForRow}
                    handleExpanded={handleExpanded}
                    toggleNewLotNoInsertionForRow={toggleNewLotNoInsertionForRow}
                    addRunAfter={addRunAfter}
                    removeRun={removeRun}
                />
            </Table.Column>
            <Table.Column width={STANDARD_CELL_WIDTH} fixed={true}>
                <Table.HeaderCell>Day</Table.HeaderCell>
                <Table.Cell dataKey="day" />
            </Table.Column>

            <Table.Column width={STANDARD_CELL_WIDTH} fixed={true}>
                <Table.HeaderCell>Date</Table.HeaderCell>
                <DateCell dataKey="date" />
            </Table.Column>

            <Table.Column width={STANDARD_CELL_WIDTH} fixed={true}>
                <Table.HeaderCell>Run</Table.HeaderCell>
                <Table.Cell dataKey="run" />
            </Table.Column>

            <Table.Column width={STANDARD_CELL_WIDTH * 3 + 15} fixed={true}>
                <Table.HeaderCell>&nbsp;</Table.HeaderCell>
                <LabelsCell cellDataConfig={cellDataConfig}/>
            </Table.Column>

            {createDynamicHeaderSection(headerData, cellDataConfig, collectUpdatedDataForRow, getInhouseBatchOptions, toggleNewLotNoInsertionForRow, cachedNewLotNoData)}
        </Table>
    ); //TODO: cleanup console errors (props to send e.g. handleExpanded)
};

export default createTable;
