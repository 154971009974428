import React from 'react';
import { useLoading } from './LoadingContext';
import "./LoadingSpinner.css";

const LoadingSpinner = () => {
  const { isLoading } = useLoading();

  return (
    isLoading ? (
      <div className="loading-spinner-overlay">
        <div className="loading-spinner"></div>
      </div>
    ) : <></>
  );
};

export default LoadingSpinner;