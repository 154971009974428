const TRINOS = Object.freeze([ 
    "First Run", 
    "Second Run", 
    "Third Run", 
    "Fourth Run", 
    "Fifth Run", 
    "Sixth Run", 
    "Seventh Run", 
    "Eighth Run", 
    "Ninth run" 
]);

const getTestRunIdentificationValue = (runNo: number): string => {
    let valueIndex = runNo - 1;
    if (valueIndex > TRINOS.length - 1 || valueIndex < 0) {
        throw new Error("Could not find a valid test run identification number. Please check the run number.");
    }
    return TRINOS[valueIndex];
};

export default getTestRunIdentificationValue;
