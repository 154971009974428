import { ProcessedGriffolsRunResultResponse, ProcessedGriffolsRunResultRow } from '../../../store/RunResultsGriffols';
import { wrapDateStringInMoment } from '../utils/dateUtils';

const MAX_NUMBER_OF_RESULT_PAGES = 5;

export type WeeklyGriffolsResultsData = {
    pages: Array<ProcessedGriffolsRunResultRow[]>;
    pageLabels: string[];
};

const getWeeklyGriffolsResults = (
    response: ProcessedGriffolsRunResultResponse
): WeeklyGriffolsResultsData => {
    let recordsPages: Array<ProcessedGriffolsRunResultRow[]> = [];
    let recordPageLabels: string[] = [];

    if (!response || !response.rows.length) {
        return {
            pages: recordsPages,
            pageLabels: recordPageLabels,
        };
    }

    let currentPage: ProcessedGriffolsRunResultRow[] = [];
    let limitDate = null;

    for (let i = 0; i < response.rows.length; i++) {
        let currentRow: ProcessedGriffolsRunResultRow = response.rows[i];
        if (!limitDate) {
            limitDate = wrapDateStringInMoment(currentRow.date).startOf(
                'isoWeek'
            );
        }
        let isBefore: boolean = wrapDateStringInMoment(
            currentRow.date
        ).isBefore(limitDate);

        if (isBefore) {
            limitDate = wrapDateStringInMoment(currentRow.date).startOf(
                'isoWeek'
            );

            recordsPages.unshift(currentPage);
            let currentPageLabel = wrapDateStringInMoment(
                currentPage[0].date
            ).isoWeek();
            recordPageLabels.unshift(currentPageLabel);

            currentPage = [];
            currentPage.push(currentRow);
        } else {
            currentPage.push(currentRow);
        }
    }

    if (currentPage.length) {
        recordsPages.unshift(currentPage);
        let currentPageLabel = wrapDateStringInMoment(
            currentPage[0].date
        ).isoWeek();
        recordPageLabels.unshift(currentPageLabel);
    }

    return {
        pages: recordsPages.slice(-MAX_NUMBER_OF_RESULT_PAGES),
        pageLabels: recordPageLabels.slice(-MAX_NUMBER_OF_RESULT_PAGES),
    };
};

export default getWeeklyGriffolsResults;
