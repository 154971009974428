import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Col, Row } from 'reactstrap';

const EquipmentRegistrationForm = (props: any) => {
    let {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    return (
        <Col>
            <h4 className="h4 fw-normal form-title">
                Notify us if your equipment has changed
            </h4>
            <form onSubmit={handleSubmit(props.submitCallback)}>
                <Row className="form-row">
                    <Col>
                        <label htmlFor="email">E-mail</label>
                        <input
                            type="email"
                            className="form-control"
                            id="email"
                            defaultValue={''}
                            {...register('email', { required: true })}
                        />
                        <span className="form-error">
                            {errors.email && 'Email is required'}
                        </span>
                    </Col>
                </Row>
                <Row className="form-row">
                    <Col>
                        <label htmlFor="manufacturer">IVD-Manufacturer</label>
                        <input
                            className="form-control"
                            id="manufacturer"
                            defaultValue={''}
                            {...register('manufacturer', { required: true })}
                        />
                        <span className="form-error">
                            {errors.manufacturer && 'Manufacturer is required'}
                        </span>
                    </Col>
                    <Col>
                        <label htmlFor="instrument">Instrument</label>
                        <input
                            className="form-control"
                            id="instrument"
                            defaultValue={''}
                            {...register('instrument', { required: true })}
                        />
                        <span className="form-error">
                            {errors.instrument && 'Instrument is required'}
                        </span>
                    </Col>
                    <Col>
                        <label htmlFor="deviceId">
                            Your device identification
                        </label>
                        <input
                            className="form-control"
                            id="deviceId"
                            defaultValue={''}
                            {...register('deviceId', { required: true })}
                        />
                        <span className="form-error">
                            {errors.deviceId &&
                                'Device identification is required'}
                        </span>
                    </Col>
                </Row>
                <Row className="form-row">
                    <Col>
                        <label htmlFor="description">
                            Please describe the changes
                        </label>
                        <textarea
                            rows={5}
                            className="form-control"
                            id="description"
                            defaultValue={''}
                            {...register('description', { required: false })}
                        />
                    </Col>
                </Row>
                <div className="form-footer">
                    <p className="info-note">
                        Note: It can take a few days until your changes are
                        visible.
                    </p>
                    <input
                        className="w-20 btn btn-primary btn-lg"
                        type="submit"
                        disabled={props.posting}
                        value="Send message"
                    />
                </div>
            </form>
        </Col>
    );
};

export default EquipmentRegistrationForm;
