import { Action, Reducer } from "redux";
import { UserData } from "./UserStore";

interface SetActiveCustomer {
    type: 'SET_ACTIVE_CUSTOMER';
    payload: UserData;
}

export const actionCreators = {
    setActiveCustomer(activeUser: UserData): SetActiveCustomer {
        return {
            type: 'SET_ACTIVE_CUSTOMER',
            payload: activeUser
        };
    }
};

export const reducer: Reducer<UserData> = (state: UserData = {} as UserData, incomingAction: Action): UserData => {
    switch (incomingAction.type) {
        case 'SET_ACTIVE_CUSTOMER':
            const action = incomingAction as SetActiveCustomer;
            return action.payload;
        default:
            return state;
    }
};
