import * as React from 'react';
import { useState, useEffect } from 'react';
import { ApplicationState } from '../../store';
import Layout from '../Layout/Layout';
import { actionCreators } from "../../store/LabEquipment";
import { connect } from "react-redux";
import { Row } from 'reactstrap';
import "./utils/LabEquipment.css";
import createEquipmentList from './utils/createEquipmentList';
import EquipmentRegistrationForm from './utils/EquipmentRegistrationForm';
import labEquipmentDataPreparator from './utils/labEquipmentDataPreparator';
import { ApiService } from '../../core/api-service';

const SERA_Q_TEST_GROUP_FILTER = "Serology";

const Serology = (props: any) => {    
    useEffect(() => {
        props.fetchLabEquipment();
    }, [])

    const [posting, setPosting] = useState<boolean>(false);

    const onSubmit = async (formData: any) => {
        setPosting(true);
        const apiService = new ApiService();
        try {
            await apiService.post("protected/customer-equipment/notify-equipment-change", formData);
            alert('Your notification has been sent!');
        } catch (error) {
            console.error(error);
            alert('Request failed, please try again');
        }
        finally {
            setPosting(false);
        }
    }

    return (
      <Layout>
        <Row className="cards-wrapper">{createEquipmentList(props.seraQEquipment)}</Row>
        <Row className="form-wrapper">
           <EquipmentRegistrationForm submitCallback={onSubmit} posting={posting}></EquipmentRegistrationForm>
        </Row>
      </Layout>
    );
}

const mapStateToProps = (state: ApplicationState) => {
    return {
        seraQEquipment: state.labEquipment ? labEquipmentDataPreparator(state.labEquipment,SERA_Q_TEST_GROUP_FILTER) : []
    };
};

const { fetchLabEquipment } = actionCreators;

export default connect(mapStateToProps, { fetchLabEquipment })(Serology);