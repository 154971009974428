import * as React from 'react';

const END_RANGE_LIMIT = 2;
const MID_RANGE_LIMIT = 1;

const includePageButtonTrimming = (fullButtonList: JSX.Element[], limit: number, activePageIndex: number): JSX.Element[] => {
    //one more element on each side of the active page or 2 more elements on one side (for the end page and start page)
    let maxIndex = limit - 1;
    let minIndex = 0;
    let currentIndex = activePageIndex;
    let isWithinStartRange = currentIndex >= minIndex && currentIndex <= minIndex + END_RANGE_LIMIT;
    let isWithinEndRange = currentIndex >= maxIndex - END_RANGE_LIMIT && currentIndex <= maxIndex;

    let visibleIntervalStart, visibleIntervalEnd;

    if (isWithinStartRange) {
        visibleIntervalStart = minIndex;
        visibleIntervalEnd = minIndex + END_RANGE_LIMIT + 1;
    } else if (isWithinEndRange) {
        visibleIntervalStart = maxIndex - END_RANGE_LIMIT - 1;
        visibleIntervalEnd = maxIndex;
    } else {
        visibleIntervalStart = currentIndex - MID_RANGE_LIMIT;
        visibleIntervalEnd = currentIndex + MID_RANGE_LIMIT;
    }

    let finalPaginationButtonsList: JSX.Element[] = [...fullButtonList.slice(visibleIntervalStart, visibleIntervalEnd + 1)];

    if (visibleIntervalStart > minIndex) {
        let insertSeparator = visibleIntervalStart - minIndex >= 1;
        if (insertSeparator) {
            finalPaginationButtonsList.unshift(<span className="pagination-separator">. . .</span>)
        }
        finalPaginationButtonsList.unshift(fullButtonList[minIndex])
    }

    if (visibleIntervalEnd < maxIndex) {
        let insertSeparator = maxIndex - visibleIntervalEnd >= 1;
        if (insertSeparator) {
            finalPaginationButtonsList.push(<span className="pagination-separator">. . .</span>)
        }
        finalPaginationButtonsList.push(fullButtonList[maxIndex])
    }

    return finalPaginationButtonsList;

}

const createPageButtons = (props: any, changePage: (e: any) => void) => {
    let pageButtons = [];
    const limit = props.pageLabels
        ? props.pageLabels.length
        : props.numberOfPages;
    for (let i = 0; i < limit; i++) {
        let isButtonActive: boolean = props.activePageIndex == i;
        let buttonClasses: string = `pagination-button ${isButtonActive ? 'active' : ''
            }`;
        pageButtons.push(
            <button
                key={`page-${i}`}
                data-page-index={i}
                className={buttonClasses}
                onClick={changePage}
            >
                {props.pageLabels ? props.pageLabels[i] : (i + 1).toString()}
            </button>
        );
    }


    pageButtons = includePageButtonTrimming(pageButtons, limit, props.activePageIndex);

    return pageButtons;
};

const createPagination = (props: any) => {
    const limit = props.pageLabels
        ? props.pageLabels.length
        : props.numberOfPages;
    const changePage = (e: MouseEvent): void => {
        let target = e.target as HTMLButtonElement;
        let value = Number(target.dataset.pageIndex);

        props.setActivePageIndex(value);
    };
    const isLastPage = props.activePageIndex === limit - 1;
    const isFirstPage = props.activePageIndex === 0;

    const previousPage = (e: any): void => {
        if (props.activePageIndex - 1 >= 0) {
            props.setActivePageIndex(props.activePageIndex - 1);
        }
    };
    const nextPage = (e: any): void => {
        if (props.activePageIndex + 1 <= limit - 1) {
            props.setActivePageIndex(props.activePageIndex + 1);
        }
    };
    return (
        <div className="pagination-strip">
            {!isFirstPage && (
                <button className="pagination-button" onClick={previousPage}>
                    {props.previousLabel || 'Previous'}
                </button>
            )}
            {createPageButtons(props, changePage)}
            {!isLastPage && (
                <button className="pagination-button" onClick={nextPage}>
                    {props.nextLabel || 'Next'}
                </button>
            )}
        </div>
    );
};

export default createPagination;
