import { AppThunkAction } from ".";
import { sanitizeProductNumber } from "../components/data-entry/utils/sanitizeProductNumber";
import { ApiService } from "../core/api-service";
import { v4 as uuidv4 } from 'uuid';
import { processDate, processDayOfWeek } from "./run-result-processing-utils";
import { Reducer, Action } from "redux";
import getTestRunIdentificationValue from "../utils/getTestRunIdentificationValue";

const DEFAULT_LABEL = "Ct value";

export interface RocheRunResult {
    productKey: string;
    lotNo: string;
    batchID: string;
    samplesTested: number;
    invalidResults: number;
    newLotNo: string;
    newLotNoExpiryDate: string;
    productName: string;
    unk_extraLabel: string;
    ic: number;
    markerData: Array<RocheMarkerData>;
    testRunIdentification: string;
}

export interface ProcessedRocheMarkers {
    [key: string]: RocheMarkerData
}

export interface RocheRunResultResponse {
    markers: string[];
    equipments: string[];
    rows: RocheRunResultsRowModel[];
}

export interface RocheMarkerData {
    runResultNo: string;
    markerName: string;
    initialReactive: number;
    repeatReactive: number;
    confirmedInfection: number;
    ctValue: number;
    markerSequence: number;
    comment: string;
    methodNo: string;
    methodName: string;
}

export interface ProcessedRocheRunResultRow {
    [key: string]: string | Date | number | RocheRunResult | boolean;
    day: string;
    date: string;
    run: number;
    uuid: string;
    customLabel: string;
    isNewRun: boolean;
}

export interface ProcessedRocheRunResultResponse {
    markers: string[];
    equipments: string[];
    rows: ProcessedRocheRunResultRow[];
}

interface FetchRocheRunResultsData {
    type: 'FETCH_ROCHE_RUN_RESULTS';
    payload: ProcessedRocheRunResultResponse;
}

export interface RocheRunResultsRowModel {
    day: number;
    date: string;
    run: number;
    products: Array<RocheRunResult>;
}

export interface RocheRunResultUpdate {
    uuid: string;
    data: Partial<RocheRunResult>;
}

interface AddRocheRunResultPayload {
    newRunResult: ProcessedRocheRunResultRow;
    addAfterIndex: number;
}

interface RemoveRocheRunResultPayload {
    removeAtIndex: number;
}

interface UpdateRocheRunResultData {
    type: 'UPDATE_ROCHE_RUN_RESULT';
    payload: RocheRunResultUpdate;
}

interface CleanupRocheRunResults {
    type: 'CLEANUP_ROCHE_RUN_RESULTS';
    payload: null;
}

interface AddRocheRunResultData {
    type: 'ADD_ROCHE_RUN_RESULT';
    payload: AddRocheRunResultPayload;
}

interface RemoveRocheRunResultData {
    type: 'REMOVE_ROCHE_RUN_RESULT';
    payload: RemoveRocheRunResultPayload;
}


const prepareModel = (response: RocheRunResultResponse): ProcessedRocheRunResultRow[] => {
    let processedModel: ProcessedRocheRunResultRow[] = [];
    response.rows.forEach((row: RocheRunResultsRowModel) => {
        let processedRow: ProcessedRocheRunResultRow = {
            uuid: uuidv4(),
            date: processDate(row.date),
            day: processDayOfWeek(row.day),
            run: row.run,
            customLabel: DEFAULT_LABEL,
            isNewRun: false
        };

        if (row.products.length) {
            let customLabel = row.products[0].unk_extraLabel;

            if (customLabel) {
                processedRow.customLabel = customLabel;
            }
        }

        row.products.forEach((runResult: RocheRunResult) => {
            runResult.markerData.sort((md1: RocheMarkerData, md2: RocheMarkerData) => {
                return md1.markerSequence - md2.markerSequence;
            });
            processedRow[runResult.productKey] = runResult;
        });

        processedModel.push(processedRow);
    });

    return processedModel;
};

const getNewRocheRunResultData = (referenceRunResult: ProcessedRocheRunResultRow): ProcessedRocheRunResultRow => {
    let newRunNo = referenceRunResult.run + 1;
    let runResultCopy = Object.assign({}, { ...referenceRunResult }, {
        run: newRunNo,
        uuid: uuidv4()
    });

    //before retunring the new RunResult object, we need to reset few of the values 
    for (let key in runResultCopy) {
        if (runResultCopy[key] instanceof Object) {
            let newMarkerData = (runResultCopy[key] as RocheRunResult).markerData.map(md => {
                return {
                    ...md,
                    comment: "",
                    ctValue: 0,
                    initialReactive: 0,
                    repeatReactive: 0,
                    confirmedInfection: 0,
                    runResultNo: uuidv4()
                }
            });

            runResultCopy[key] = Object.assign({}, { ...(runResultCopy[key] as RocheRunResult) }, {
                samplesTested: 0,
                invalidResults: 0,
                unitRatio: 0,
                ic: 0,
                markerData: newMarkerData,
                testRunIdentification: getTestRunIdentificationValue(newRunNo)
            })
        }
    }

    return runResultCopy;
}

export const rocheRunResultsActionCreators = {
    fetchRocheRunResults:
        (

            itemNo: string
        ): AppThunkAction<FetchRocheRunResultsData> =>
            async (dispatch) => {
                const apiService = new ApiService();
                const response = await apiService.get(
                    `protected/runresult/run-results/roche?productNo=${sanitizeProductNumber(itemNo)}`
                );
                const processedResponseModel = prepareModel(response);

                dispatch({
                    type: 'FETCH_ROCHE_RUN_RESULTS',
                    payload: {
                        markers: response.markers,
                        equipments: response.equipments,
                        rows: processedResponseModel,
                    },
                });
            },

    updateRocheRunResult: (uuid: string, runData: Partial<RocheRunResult>): UpdateRocheRunResultData => {
        return {
            type: 'UPDATE_ROCHE_RUN_RESULT',
            payload: {
                uuid: uuid,
                data: runData
            }
        }
    },

    cleanupRocheRunResults: (): CleanupRocheRunResults => {
        return {
            type: 'CLEANUP_ROCHE_RUN_RESULTS',
            payload: null
        }
    },

    addNewRocheRun: (uuid: string): AppThunkAction<AddRocheRunResultData> => (dispatch, getState) => {
        const state = getState();
        const sourceRunResultIndex = state.runResultsRoche?.rows.findIndex((crtRow: ProcessedRocheRunResultRow) => {
            return crtRow.uuid === uuid;
        });

        if (sourceRunResultIndex !== undefined && sourceRunResultIndex !== -1) {
            let sourceRunResult: ProcessedRocheRunResultRow = state.runResultsRoche!.rows[sourceRunResultIndex];

            const newRunResult = getNewRocheRunResultData(sourceRunResult);

            // save new run result somehow ?  save new row now or when hitting save?
            // const apiService = new ApiService();
            // const response = await apiService.post(
            //     `protected/runresult/add-run-results?...runData`
            // );

            dispatch({
                type: 'ADD_ROCHE_RUN_RESULT',
                payload: {
                    newRunResult: newRunResult,
                    addAfterIndex: sourceRunResultIndex
                },
            });

            return newRunResult.uuid;
        }
    },

    removeRocheRun: (uuid: string): AppThunkAction<RemoveRocheRunResultData> => (dispatch, getState) => {
        const state = getState();
        const resultToDeleteIndex = state.runResultsRoche?.rows.findIndex((crtRow: ProcessedRocheRunResultRow) => {
            return crtRow.uuid === uuid;
        });

        if (resultToDeleteIndex) {
            dispatch({
                type: 'REMOVE_ROCHE_RUN_RESULT',
                payload: {
                    removeAtIndex: resultToDeleteIndex
                }
            });
        }
    }
};

export const reducer: Reducer<ProcessedRocheRunResultResponse | null> = (
    state: ProcessedRocheRunResultResponse | null = null,
    incomingAction: Action
): ProcessedRocheRunResultResponse | null => {
    const action = incomingAction as FetchRocheRunResultsData | UpdateRocheRunResultData | CleanupRocheRunResults | AddRocheRunResultData | RemoveRocheRunResultData;

    switch (action.type) {
        case 'FETCH_ROCHE_RUN_RESULTS':
        case 'CLEANUP_ROCHE_RUN_RESULTS':
            return action.payload;
        case 'UPDATE_ROCHE_RUN_RESULT': {
            //TODO: update this
            let index = state?.rows.findIndex((row: ProcessedRocheRunResultRow) => {
                return row.uuid === action.payload.uuid
            })
            let newRows: ProcessedRocheRunResultRow[] = [...state!.rows];
            let newRow: ProcessedRocheRunResultRow = Object.assign({}, newRows[index!], action.payload.data, { isNewRun: false })

            newRows.splice(index!, 1, newRow);
            let nextState: ProcessedRocheRunResultResponse = Object.assign({}, state!, {
                rows: newRows
            });

            return nextState;
        }
        case 'ADD_ROCHE_RUN_RESULT': {
            //insert at the correct index (model entries are sorted in descending order by date criteria)
            let newRows = [
                ...state!.rows.slice(0, action.payload.addAfterIndex + 1),
                Object.assign({}, action.payload.newRunResult, { isNewRun: true }),
                ...state!.rows.slice(action.payload.addAfterIndex + 1)
            ];

            let nextState: ProcessedRocheRunResultResponse = Object.assign({}, state!, {
                rows: newRows
            });

            return nextState;
        }

        case 'REMOVE_ROCHE_RUN_RESULT': {
            let removedRowDate = state?.rows[action.payload.removeAtIndex].date
            let newRows = [
                ...state!.rows.slice(0, action.payload.removeAtIndex),
                ...state!.rows.slice(action.payload.removeAtIndex + 1)
            ]

            //update (decrease count) run number values for all next runs
            for (let i = action.payload.removeAtIndex; i <= newRows.length; i++) {
                let currentRow = newRows[i];
                if (currentRow.date === removedRowDate) {
                    currentRow.run -= 1;
                } else {
                    break;
                }
            }

            let nextState: ProcessedRocheRunResultResponse = Object.assign({}, state!, {
                rows: newRows
            });

            return nextState;
        }
        default:
            return state;
    }
};
