export class ServiceResult<T> {
    /**
     *
     */
    constructor(success: boolean, value: T | null) {
        this.success = success;
        this.value = value;
    }

    success: boolean;
    value: T | null;
}
