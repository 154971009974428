import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { AppRoutes } from '../../core/constants';
import authService from '../authorization/AuthorizeService';
import "./Login.css";
import banner from "../../assets/images/banner.png";
import logo from "../../assets/images/logo.png";

const AuthorizeAuthenticator = (props: any) => {
    let {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const history = useHistory();

    const onSubmit = async (data: any) => {
        const code = data.code;
        try {
            const jwtToken = await authService.validateAuthenticatorCode(code);
            if (!jwtToken) {
                throw new Error('JWT token');
            }

            authService.signInSecondStep(jwtToken);
            console.log('Email verification code success');
            history.replace(AppRoutes.CustomerSelect);
        } catch (error) {
            alert('Something went wrong, please try again');
        }
    }

    return (
        <>
            <div className='login-header'>
                <img className="logo" src={logo} />
                <img className="banner" src={banner} />
            </div>
            <div className="login-wrapper">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-title-wrapper">
                        <h1> DataQ Analytics</h1>
                        <h3 className="h3 mb-3 fw-normal">Please enter the code from the Authenticator app.</h3>
                    </div>
                    <div className="form-row">
                        <label htmlFor="code">Code</label>
                        <input
                            type="text"
                            className="form-control"
                            id="code"
                            placeholder=""
                            defaultValue={''}
                            {...register('code', { required: true })}
                        />
                        {errors.code && 'The code is required'}

                        {/* <button className="w-100 btn btn-primary btn-lg">Reset authenticator code</button> */}
                    </div>
                    <div className="form-footer">
                        <input className="w-100 btn btn-primary btn-lg" type="submit" />
                        <p className="mt-5 mb-3 text-muted">&copy; 2022</p>
                    </div>
                </form>
            </div>
        </>
    );
};

export default AuthorizeAuthenticator;
