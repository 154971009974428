import React, {MouseEvent} from "react";

const Link = (props: any) => {
    const { className, href, children} = props;
    const onClick = (event: MouseEvent): void => {
        if (event.metaKey || event.ctrlKey) {
            return;
        }
        
        event.preventDefault();
        window.history.pushState({}, "", href);

        const navEvent = new PopStateEvent("popstate");
        window.dispatchEvent(navEvent);
    };

    return (
        <a onClick={onClick} className={className} href={href}>{children}</a>
    );
};

export default Link;