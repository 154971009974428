import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { ApiService } from '../core/api-service';
import { v4 as uuidv4 } from 'uuid';
import { sanitizeProductNumber } from '../components/data-entry/utils/sanitizeProductNumber';
import { processDate, processDayOfWeek } from './run-result-processing-utils';
import getTestRunIdentificationValue from '../utils/getTestRunIdentificationValue';

export interface Marker {
    marker_ID_Name: string;
}

export interface RunResultResponse {
    equipments: string[];
    rows: RunResultsRowModel[];
}
export interface RunResultsRowModel {
    day: number;
    date: string;
    run: number;
    products: Array<RunResult>;
}
export interface RunResult {
    productKey: string;
    lotNo: string;
    methodNo: string;
    batchID: string;
    runResultNo: string;
    samplesTested: number;
    initialReactive: number;
    invalidResults: number;
    repeatReactive: number;
    confirmedInfection: number;
    unitRatio: number;
    comment: string;
    newLotNo: string;
    newLotNoExpiryDate: string;
    methodName: string;
    productName: string;
    testRunIdentification: string;
}

export type CachedLotNoData = {
    newLotNo: string;
    newLotNoExpiryDate: string;
}

export interface ProcessedRunResultResponse {
    equipments: string[];
    rows: ProcessedRunResultRow[];
}

export interface RunResultUpdate {
    uuid: string;
    data: Partial<RunResult>;
}

export interface ProcessedRunResultRow {
    [key: string]: string | Date | number | RunResult | boolean;
    day: string;
    date: string;
    run: number;
    uuid: string;
    isNewRun: boolean;
}
interface FetchRunResultsData {
    type: 'FETCH_RUN_RESULTS';
    payload: ProcessedRunResultResponse;
}

interface UpdateRunResultData {
    type: 'UPDATE_RUN_RESULT';
    payload: RunResultUpdate;
}

interface AddRunResultPayload {
    newRunResult: ProcessedRunResultRow;
    addAfterIndex: number;
}

interface RemoveRunResultPayload {
    removeAtIndex: number;
}

interface AddRunResultData {
    type: 'ADD_RUN_RESULT';
    payload: AddRunResultPayload;
}

interface RemoveRunResultData {
    type: 'REMOVE_RUN_RESULT';
    payload: RemoveRunResultPayload;
}

interface CleanupRunResults {
    type: 'CLEANUP_RUN_RESULTS';
    payload: null;
}

const getNewRunResultData = (referenceRunResult: ProcessedRunResultRow): ProcessedRunResultRow => {
    let newRunNo = referenceRunResult.run + 1;
    let runResultCopy = Object.assign({}, { ...referenceRunResult }, {
        run: newRunNo,
        uuid: uuidv4()
    });

    //before retunring the new RunResult object, we need to reset few of the values 
    for (let key in runResultCopy) {
        if (runResultCopy[key] instanceof Object) {
            runResultCopy[key] = Object.assign({}, { ...(runResultCopy[key] as RunResult) }, {
                comment: "",
                samplesTested: 0,
                initialReactive: 0,
                invalidResults: 0,
                repeatReactive: 0,
                confirmedInfection: 0,
                unitRatio: 0,
                runResultNo: uuidv4(),
                testRunIdentification: getTestRunIdentificationValue(newRunNo)
            })
        }
    }

    return runResultCopy;
}

const prepareModel = (response: RunResultResponse): ProcessedRunResultRow[] => {
    let processedModel: ProcessedRunResultRow[] = [];
    response.rows.forEach((row: RunResultsRowModel) => {
        let processedRow: ProcessedRunResultRow = {
            uuid: uuidv4(),
            date: processDate(row.date),
            day: processDayOfWeek(row.day),
            run: row.run,
            isNewRun: false
        };

        row.products.forEach((runResult: RunResult) => {
            processedRow[runResult.productKey] = runResult;
        });

        processedModel.push(processedRow);
    });

    return processedModel;
};

export const runResultsActionCreators = {
    fetchRunResults:
        (
            customerId: number,
            itemNo: string,
            markerName: string
        ): AppThunkAction<FetchRunResultsData> =>
            async (dispatch) => {
                const apiService = new ApiService();
                const response = await apiService.get(
                    `protected/runresult/run-results?productNo=${sanitizeProductNumber(itemNo)}&markerName=${markerName}`
                );
                const processedResponseModel = prepareModel(response);

                dispatch({
                    type: 'FETCH_RUN_RESULTS',
                    payload: {
                        equipments: response.equipments,
                        rows: processedResponseModel,
                    },
                });
            },

    updateRunResult: (uuid: string, runData: Partial<RunResult>): UpdateRunResultData => {
        return {
            type: 'UPDATE_RUN_RESULT',
            payload: {
                uuid: uuid,
                data: runData
            }
        }
    },

    cleanupRunResults: (): CleanupRunResults => {
        return {
            type: 'CLEANUP_RUN_RESULTS',
            payload: null
        }
    },

    addNewRun: (uuid: string): AppThunkAction<AddRunResultData> => (dispatch, getState) => {
        const state = getState();
        const sourceRunResultIndex = state.runResults?.rows.findIndex((crtRow: ProcessedRunResultRow) => {
            return crtRow.uuid === uuid;
        });

        if (sourceRunResultIndex !== undefined && sourceRunResultIndex !== -1) {
            let sourceRunResult: ProcessedRunResultRow = state.runResults!.rows[sourceRunResultIndex];

            const newRunResult = getNewRunResultData(sourceRunResult);

            // save new run result somehow ?  save new row now or when hitting save?
            // const apiService = new ApiService();
            // const response = await apiService.post(
            //     `protected/runresult/add-run-results?...runData`
            // );

            dispatch({
                type: 'ADD_RUN_RESULT',
                payload: {
                    newRunResult: newRunResult,
                    addAfterIndex: sourceRunResultIndex
                },
            });

            return newRunResult.uuid;
        }
    },

    removeRun: (uuid: string): AppThunkAction<RemoveRunResultData> => (dispatch, getState) => {
        const state = getState();
        const resultToDeleteIndex = state.runResults?.rows.findIndex((crtRow: ProcessedRunResultRow) => {
            return crtRow.uuid === uuid;
        });

        if (resultToDeleteIndex) {
            dispatch({
                type: 'REMOVE_RUN_RESULT',
                payload: {
                    removeAtIndex: resultToDeleteIndex
                }
            });
        }
    }
};

export const reducer: Reducer<ProcessedRunResultResponse | null> = (
    state: ProcessedRunResultResponse | null = null,
    incomingAction: Action
): ProcessedRunResultResponse | null => {
    const action = incomingAction as FetchRunResultsData | UpdateRunResultData | AddRunResultData | RemoveRunResultData | CleanupRunResults;

    switch (action.type) {
        case 'FETCH_RUN_RESULTS':
        case 'CLEANUP_RUN_RESULTS':
            return action.payload;

        case 'UPDATE_RUN_RESULT': {
            let index = state?.rows.findIndex((row: ProcessedRunResultRow) => {
                return row.uuid === action.payload.uuid
            })
            let newRows: ProcessedRunResultRow[] = [...state!.rows];
            let newRow: ProcessedRunResultRow = Object.assign({}, newRows[index!], action.payload.data, { isNewRun: false })

            newRows.splice(index!, 1, newRow);
            let nextState: ProcessedRunResultResponse = Object.assign({}, state!, {
                rows: newRows
            });

            return nextState;
        }

        case 'ADD_RUN_RESULT': {
            //insert at the correct index (model entries are sorted in descending order by date criteria)
            let newRows = [
                ...state!.rows.slice(0, action.payload.addAfterIndex + 1),
                Object.assign({}, action.payload.newRunResult, { isNewRun: true }),
                ...state!.rows.slice(action.payload.addAfterIndex + 1)
            ];

            let nextState: ProcessedRunResultResponse = Object.assign({}, state!, {
                rows: newRows
            });

            return nextState;
        }

        case 'REMOVE_RUN_RESULT': {
            let removedRowDate = state?.rows[action.payload.removeAtIndex].date
            let newRows = [
                ...state!.rows.slice(0, action.payload.removeAtIndex),
                ...state!.rows.slice(action.payload.removeAtIndex + 1)
            ]

            //update (decrease count) run number values for all next runs
            for (let i = action.payload.removeAtIndex; i <= newRows.length; i++) {
                let currentRow = newRows[i];
                if (currentRow.date === removedRowDate) {
                    currentRow.run -= 1;
                } else {
                    break;
                }
            }

            let nextState: ProcessedRunResultResponse = Object.assign({}, state!, {
                rows: newRows
            });

            return nextState;
        }

        default:
            return state;
    }
};
