import axios from 'axios';
import store from '../..';
import { UserLoginRequestModel } from './models/UserLoginRequestModel';
import { UserLoginResponseModel } from './models/UserLoginResponseModel';
import { UserManager } from './UserManager';
import { UserProfile } from './models/UserProfile';
import { ServiceResult } from '../../core/service-result';
import { AuthenticatorEnrollmentResponse } from './models/AuthenticatorEnrollmentResponse';
import { AuthenticatorValidationRequest } from './models/AuthenticatorValidationRequest';
import { BaseUrl } from '../../core/constants';

export class AuthorizeService {
    _user: UserProfile | null = null;
    _userManager: UserManager = new UserManager();
    _loginData: UserLoginRequestModel | null = null;

    async initializeAuthenticationState(): Promise<boolean> {
        const authorization = this._userManager.getAuthorization();
        if (!authorization) {
            return false; // user is not logged in
        }

        const userLogin = this._userManager.getUser();
        if (!userLogin) {
            return false;
        }

        const activeCustomer = this._userManager.getActiveCustomer();
        if (activeCustomer) {
            store.dispatch({
                type: 'SET_ACTIVE_CUSTOMER',
                payload: activeCustomer,
            });
        }

        if (authorization.startsWith('Bearer')) {
            store.dispatch({
                type: 'SECOND_STEP_SUCCESS',
                data: authorization,
            });

            return true;
        }

        return false;
    }

    isAuthenticated() {
        const user = this.getUser();
        return !!user;
    }

    getUser() {
        if (!this._user) {
            this._user = this._userManager.getUser();
        }

        return this._user;
    }

    getUserManager() {
        return this._userManager;
    }

    getAuthToken() {
        return this._userManager.getAuthorization();
    }

    async signInFirstStep(
        userLogin: UserLoginRequestModel
    ): Promise<ServiceResult<UserLoginResponseModel | null>> {
        try {
            this._loginData = userLogin;
            const response = await axios.post(
                BaseUrl + 'authentication/login',
                userLogin
            );
            if (response.status >= 200 && response.status < 300) {
                const responseData = response.data as UserLoginResponseModel;
                this._userManager.setAuthorization(
                    responseData.authorization
                );
                this._userManager.setUser({
                    username: userLogin.username
                });

                store.dispatch({
                    type: 'FIRST_STEP_SUCCESS',
                    data: responseData,
                });

                return new ServiceResult(true, responseData);
            }
            return new ServiceResult(false, null);
        } catch (error) {
            return new ServiceResult(false, null);
        }
    }

    signInSecondStep(token: string) {
        this._userManager.setAuthorization('Bearer ' + token);
        store.dispatch({
            type: 'SECOND_STEP_SUCCESS',
            data: token,
        });
    }

    signOut(): void {
        this._userManager.removeAuthorization();
        this._userManager.removeUser();
    }

    async getAuthenticatorRegistration(): Promise<ServiceResult<AuthenticatorEnrollmentResponse>> {
        const response = await axios.get(
            `${BaseUrl}authentication/authenticator-registration`, {
                headers: {
                    Authorization: this._userManager.getAuthorization()
                }
            }
        );
        return new ServiceResult(!!response.data, response.data);
    }

    async validateAuthenticatorEnrollment(
        request: AuthenticatorValidationRequest
    ): Promise<boolean> {
        try {
            await axios.post(
                BaseUrl + 'authentication/authenticator-registration',
                request,
                {
                    headers: {
                        Authorization: this._userManager.getAuthorization()
                    }
                }
            );
        } catch (error) {
            console.error(error);
            return false;
        }
        return true;
    }

    async sendEmailCode(): Promise<any> {
        await axios.post(BaseUrl + 'authentication/two-factor-email-send-code', null, {
            headers: {
                Authorization: this._userManager.getAuthorization(),
            },
        });
    }

    async validateEmailCode(code: string): Promise<string> {
        const result = await axios.post(
            `${BaseUrl}authentication/validate-email-code?code=${code}`,
            null,
            {
                headers: {
                    Authorization: this._userManager.getAuthorization(),
                },
            }
        );
        return result.data;
    }

    async validateAuthenticatorCode(code: string): Promise<string> {
        const result = await axios.post(
            `${BaseUrl}authentication/authenticator-validation`,
            {
                authenticatorCode: code
            },
            {
                headers: {
                    Authorization: this._userManager.getAuthorization(),
                },
            }
        );
        return result.data;
    }

    async validateJwtToken(): Promise<boolean> {
        try {
            await axios.get(`${BaseUrl}authentication/confirm-token`, {
                headers: {
                    Authorization: this._userManager.getAuthorization(),
                },
            });
            return true;
        } catch (error) {
            return false;
        }
    }
}

const authService = new AuthorizeService();

export default authService;
