import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import Breadcrumbs from '../utilitary-components/Breadcrumbs';
import NavMenu from './NavMenu';
import './Layout.css';
import { ApplicationState } from '../../store';
import { connect } from 'react-redux';
import { UserData } from '../../store/UserStore';
import { useHistory } from 'react-router';
import { AppRoutes } from '../../core/constants';
import { ApiService } from '../../core/api-service';
import authService from '../authorization/AuthorizeService';
import LoadingSpinner from '../utilitary-components/LoadingSpinner';

// interface LayoutProps {
//     activeUser: UserData | string | undefined;
//     userDetails: UserData[];
//     children: any[] | any | undefined;
// }

const Layout = (props: any) => {
    const history = useHistory();

    useEffect(() => {
        const fetchData = async () => {
            let activeUser = props.activeUser;
            let customers = props.userDetails;
            const username = authService.getUser()?.username;

            if (username) {
                setUsername(username);
            }

            if (customers.length === 0) {
                const apiService = new ApiService();
                const data = await apiService.get(
                    `protected/userProfile?username=${username}`
                );
                customers = data.customers;
            }
            if (activeUser) {
                if (typeof activeUser === 'string') {
                    activeUser = customers.find(
                        (u: any) => u.no === activeUser
                    );
                }
                setUserDetails(activeUser);
            } else {
                console.error('no active user, should not be the case!');
                history.replace(AppRoutes.Login);
            }
        };

        fetchData().catch(console.error);
    }, [props.activeUser, props.userDetails]);

    const [userDetails, setUserDetails] = useState<UserData | null>(null);
    const [username, setUsername] = useState<string>("");

    return (
        <React.Fragment>
            <NavMenu />
            <Container fluid={true}>
                <Container>
                    <Breadcrumbs></Breadcrumbs>
                    <Row className="customer-wrapper">
                        <Col>
                            <span className="customer-label">Customer:</span>
                            <span className="customer-name">
                                {userDetails?.name} ({userDetails?.no})
                            </span>
                            {username && (
                                <span className="user-name"> - {username}</span>
                            )}
                        </Col>
                    </Row>
                </Container>
                <Container fluid={props.contentFluid}>
                    {props.children}
                    <LoadingSpinner />
                </Container>
            </Container>
        </React.Fragment>
    );
};

const mapStateToProps = (state: ApplicationState) => {
    return {
        activeUser: state.activeUser,
        userDetails: state.userDetails,
    };
};

export default connect(mapStateToProps, {})(Layout);
