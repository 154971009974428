import { UserLoginResponseModel } from './../components/authorization/models/UserLoginResponseModel';

import { Action, Reducer } from 'redux';
export interface AuthState {
    firstStepSuccess: boolean;
    isLoggedIn: boolean;
    data: UserLoginResponseModel|null;
}

export interface FirstStepSuccessAction {
    type: 'FIRST_STEP_SUCCESS';
    data: UserLoginResponseModel
}

export interface SecondStepSuccessAction {
    type: 'SECOND_STEP_SUCCESS';
}

export type KnownAction = FirstStepSuccessAction | SecondStepSuccessAction;

export const actionCreators = {
    signInFirstStep: () =>
        ({ type: 'FIRST_STEP_SUCCESS' } as FirstStepSuccessAction),
    signInSecondStep: () =>
        ({ type: 'SECOND_STEP_SUCCESS' } as SecondStepSuccessAction),
};

export const reducer: Reducer<AuthState> = (
    state: AuthState | undefined,
    incomingAction: Action
): AuthState => {
    if (state === undefined) {
        return { isLoggedIn: false, firstStepSuccess: false, data: null };
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'FIRST_STEP_SUCCESS':
            return { isLoggedIn: false, firstStepSuccess: true, data: action.data };
        case 'SECOND_STEP_SUCCESS':
            return { firstStepSuccess: true, isLoggedIn: true, data: null };
        default:
            return state;
    }
};
