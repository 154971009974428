import * as React from 'react';
import { Card, ListGroup } from 'reactstrap';
import Layout from '../Layout/Layout';
import { actionCreators } from "../../store/Products";
import { ApplicationState } from '../../store';
import { connect } from "react-redux";
import { createSimpleProductsList } from './utils/createProductsAndMarkers';
import "./DataEntry.css";
import { useLoading } from '../utilitary-components/LoadingContext';

//all non serology results are viraq
const SERA_Q_TEST_GROUP_FILTER = "Serology";

const ViraQ = (props: any) => {
  React.useEffect(() => {
    props.fetchProducts();
  }, [])

  const { showLoading, hideLoading } = useLoading();

  const displayLoading = () => {
    showLoading();
    return <></>
  }

  const displayContent = () => {
    hideLoading();
    return <Card><ListGroup flush>
      {createSimpleProductsList(props.products)}
    </ListGroup></Card>
  }

  const displayEmptyContent = () => {
    hideLoading();
    return <div>No valid products found</div>
  }

  return (<Layout>
    {!props.products && displayLoading()}
    {props.products && !!props.products.length && displayContent()}
    {props.products && !props.products.length && displayEmptyContent()}
  </Layout>);
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    products: state.products && state.products.length ? state.products.filter(product => product.method_test_group !== SERA_Q_TEST_GROUP_FILTER) : null
  };
};

const { fetchProducts } = actionCreators;

export default connect(mapStateToProps, { fetchProducts })(ViraQ);