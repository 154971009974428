import * as React from 'react';
import { Icon } from '@rsuite/icons';
import { FaPen, FaCloudUploadAlt, FaPlus, FaRegTimesCircle, FaTrash } from 'react-icons/fa';
import { Button, Table } from 'rsuite';
import { COUNTER_OPERATION } from '../utils/types';

export const EditCell = ({ ...props }) => (
    <Table.Cell {...props} className="edit-cell">
        {!props.rowData.isInEditMode && (
            <Button
                className="in-cell-button"
                appearance="subtle"
                size="md"
                onClick={() => {
                    props.handleExpanded(props.rowData.uuid);
                    props.toggleEditModeForRow(props.rowData.uuid, true);
                }}
            >
                <span>Edit results</span>
                <Icon as={FaPen} />
            </Button>
        )}

        {/* next two buttons refer to add run and delete run functionalities. they were initially displayed in edit mode only */}
        {/* {props.rowData.run !== 1 && !props.rowData.isInEditMode && (
            <Button
                className="in-cell-button"
                appearance="subtle"
                size="md"
                onClick={() => {
                    props.toggleEditModeForRow(props.rowData.uuid, false, true);
                    props.removeRun(props.rowData.uuid);
                }}
            >
                <span>Delete run</span>
                <Icon as={FaTrash} />
            </Button>
        )} */}
        {props.rowData.isLastRunOfTheDay && !props.rowData.isInEditMode && props.rowData.run < 9 && (
            <Button
                className="in-cell-button"
                appearance="subtle"
                size="md"
                onClick={() => {
                    props.addRunAfter(props.rowData.uuid);
                }}
            >
                <span>Add run</span>
                <Icon as={FaPlus} />
            </Button>
        )}

        {props.rowData.isInEditMode && (
            <div>
                <Button
                    className="in-cell-button"
                    appearance="subtle"
                    size="md"
                    onClick={() => {
                        props.handleExpanded(props.rowData.uuid);
                        props.toggleEditModeForRow(props.rowData.uuid, false);
                    }}
                >
                    <span>Save results</span>
                    <Icon as={FaCloudUploadAlt} />
                </Button>
                <Button
                    className="in-cell-button"
                    appearance="subtle"
                    size="md"
                    onClick={() => {
                        if (props.rowData.isNewRun) {
                            props.toggleEditModeForRow(props.rowData.uuid, false, true);
                            props.removeRun(props.rowData.uuid);
                        } else {
                            props.handleExpanded(props.rowData.uuid);
                            props.toggleEditModeForRow(props.rowData.uuid, false, true);
                            props.toggleNewLotNoInsertionForRow(props.rowData.id, COUNTER_OPERATION.RECALCULATE);
                        }
                    }}
                >
                    <span>Discard changes</span>
                    <Icon as={FaRegTimesCircle} />
                </Button>
            </div>
        )}
    </Table.Cell>
);
