import { AppThunkAction } from ".";
import { sanitizeProductNumber } from "../components/data-entry/utils/sanitizeProductNumber";
import { ApiService } from "../core/api-service";
import { v4 as uuidv4 } from 'uuid';
import { processDate, processDayOfWeek } from "./run-result-processing-utils";
import { Reducer, Action } from "redux";
import getTestRunIdentificationValue from "../utils/getTestRunIdentificationValue";

const DEFAULT_LABEL = "S/CO value";

export interface GriffolsRunResult {
    productKey: string;
    lotNo: string;
    samplesTested: number;
    invalidResults: number;
    initialReactive: number;

    newLotNo: string;
    newLotNoExpiryDate: string;
    productName: string;

    unk_extraLabel: string;
    markerData: Array<GriffolsMarkerData>;
    testRunIdentification: string;
}

export interface ProcessedGriffolsMarkers {
    [key: string]: GriffolsMarkerData
}

export interface GriffolsRunResultResponse {
    markers: string[];
    equipments: string[];
    rows: GriffolsRunResultsRowModel[];
}

export interface GriffolsMarkerData {
    ic: number;
    batchID: string;
    runResultNo: string;
    markerName: string;
    repeatReactive: number;
    confirmedInfection: number;
    ctValue: number;
    markerSequence: number;
    comment: string;
    itemNo: string;
    methodNo: string;
    methodName: string;
}

export interface ProcessedGriffolsRunResultRow {
    [key: string]: string | Date | number | GriffolsRunResult | boolean;
    day: string;
    date: string;
    run: number;
    uuid: string;
    customLabel: string;
    isNewRun: boolean;
}

export interface ProcessedGriffolsRunResultResponse {
    markers: string[];
    equipments: string[];
    rows: ProcessedGriffolsRunResultRow[];
}

interface FetchGriffolsRunResultsData {
    type: 'FETCH_GRIFFOLS_RUN_RESULTS';
    payload: ProcessedGriffolsRunResultResponse;
}

export interface GriffolsRunResultsRowModel {
    day: number;
    date: string;
    run: number;
    products: Array<GriffolsRunResult>;
}

export interface GriffolsRunResultUpdate {
    uuid: string;
    data: Partial<GriffolsRunResult>;
}

interface AddGriffolsRunResultPayload {
    newRunResult: ProcessedGriffolsRunResultRow;
    addAfterIndex: number;
}

interface RemoveGriffolsRunResultPayload {
    removeAtIndex: number;
}


interface UpdateGriffolsRunResultData {
    type: 'UPDATE_GRIFFOLS_RUN_RESULT';
    payload: GriffolsRunResultUpdate;
}

interface CleanupGriffolsRunResults {
    type: 'CLEANUP_GRIFFOLS_RUN_RESULTS';
    payload: null;
}

interface AddGriffolsRunResultData {
    type: 'ADD_GRIFFOLS_RUN_RESULT';
    payload: AddGriffolsRunResultPayload;
}

interface RemoveGriffolsRunResultData {
    type: 'REMOVE_GRIFFOLS_RUN_RESULT';
    payload: RemoveGriffolsRunResultPayload;
}


const prepareModel = (response: GriffolsRunResultResponse): ProcessedGriffolsRunResultRow[] => {
    let processedModel: ProcessedGriffolsRunResultRow[] = [];
    response.rows.forEach((row: GriffolsRunResultsRowModel) => {
        let processedRow: ProcessedGriffolsRunResultRow = {
            uuid: uuidv4(),
            date: processDate(row.date),
            day: processDayOfWeek(row.day),
            run: row.run,
            customLabel: DEFAULT_LABEL,
            isNewRun: false
        };

        if (row.products.length) {
            let customLabel = row.products[0].unk_extraLabel;

            if (customLabel) {
                processedRow.customLabel = customLabel;
            }
        }

        row.products.forEach((runResult: GriffolsRunResult) => {
            runResult.markerData.sort((md1: GriffolsMarkerData, md2: GriffolsMarkerData) => {
                return md1.markerSequence - md2.markerSequence;
            });
            processedRow[runResult.productKey] = runResult;
        });

        processedModel.push(processedRow);
    });

    return processedModel;
};

const getNewGriffolsRunResultData = (referenceRunResult: ProcessedGriffolsRunResultRow): ProcessedGriffolsRunResultRow => {
    let newRunNo = referenceRunResult.run + 1;
    let runResultCopy = Object.assign({}, { ...referenceRunResult }, {
        run: newRunNo,
        uuid: uuidv4()
    });

    //before retunring the new RunResult object, we need to reset few of the values 
    for (let key in runResultCopy) {
        if (runResultCopy[key] instanceof Object) {
            let newMarkerData = (runResultCopy[key] as GriffolsRunResult).markerData.map(md => {
                return {
                    ...md,
                    comment: "",
                    ctValue: 0,
                    ic: 0,
                    repeatReactive: 0,
                    confirmedInfection: 0,
                    runResultNo: uuidv4()
                }
            });

            runResultCopy[key] = Object.assign({}, { ...(runResultCopy[key] as GriffolsRunResult) }, {
                samplesTested: 0,
                initialReactive: 0,
                invalidResults: 0,
                testRunIdentification: getTestRunIdentificationValue(newRunNo),
                markerData: newMarkerData
            })
        }
    }

    return runResultCopy;
}

export const griffolsRunResultsActionCreators = {
    fetchGriffolsRunResults:
        (
            methodGroup: string
        ): AppThunkAction<FetchGriffolsRunResultsData> =>
            async (dispatch) => {
                const apiService = new ApiService();
                //we might not need to sanitize the reagent group (it might not containe the '/' character);
                const response = await apiService.get(
                    `protected/runresult/run-results/grifols?methodGroup=${sanitizeProductNumber(methodGroup)}`
                );
                const processedResponseModel = prepareModel(response);

                dispatch({
                    type: 'FETCH_GRIFFOLS_RUN_RESULTS',
                    payload: {
                        markers: response.markers,
                        equipments: response.equipments,
                        rows: processedResponseModel,
                    },
                });
            },

    updateGriffolsRunResult: (uuid: string, runData: Partial<GriffolsRunResult>): UpdateGriffolsRunResultData => {
        return {
            type: 'UPDATE_GRIFFOLS_RUN_RESULT',
            payload: {
                uuid: uuid,
                data: runData
            }
        }
    },

    cleanupGriffolsRunResults: (): CleanupGriffolsRunResults => {
        return {
            type: 'CLEANUP_GRIFFOLS_RUN_RESULTS',
            payload: null
        }
    },
    addNewGriffolsRun: (uuid: string): AppThunkAction<AddGriffolsRunResultData> => (dispatch, getState) => {
        const state = getState();
        const sourceRunResultIndex = state.runResultsGriffols?.rows.findIndex((crtRow: ProcessedGriffolsRunResultRow) => {
            return crtRow.uuid === uuid;
        });

        if (sourceRunResultIndex !== undefined && sourceRunResultIndex !== -1) {
            let sourceRunResult: ProcessedGriffolsRunResultRow = state.runResultsGriffols!.rows[sourceRunResultIndex];

            const newRunResult = getNewGriffolsRunResultData(sourceRunResult);

            // save new run result somehow ?  save new row now or when hitting save?
            // const apiService = new ApiService();
            // const response = await apiService.post(
            //     `protected/runresult/add-run-results?...runData`
            // );

            dispatch({
                type: 'ADD_GRIFFOLS_RUN_RESULT',
                payload: {
                    newRunResult: newRunResult,
                    addAfterIndex: sourceRunResultIndex
                },
            });

            return newRunResult.uuid;
        }
    },

    removeGriffolsRun: (uuid: string): AppThunkAction<RemoveGriffolsRunResultData> => (dispatch, getState) => {
        const state = getState();
        const resultToDeleteIndex = state.runResultsGriffols?.rows.findIndex((crtRow: ProcessedGriffolsRunResultRow) => {
            return crtRow.uuid === uuid;
        });

        if (resultToDeleteIndex) {
            dispatch({
                type: 'REMOVE_GRIFFOLS_RUN_RESULT',
                payload: {
                    removeAtIndex: resultToDeleteIndex
                }
            });
        }
    }
};

export const reducer: Reducer<ProcessedGriffolsRunResultResponse | null> = (
    state: ProcessedGriffolsRunResultResponse | null = null,
    incomingAction: Action
): ProcessedGriffolsRunResultResponse | null => {
    const action = incomingAction as FetchGriffolsRunResultsData | UpdateGriffolsRunResultData | CleanupGriffolsRunResults | AddGriffolsRunResultData | RemoveGriffolsRunResultData;

    switch (action.type) {
        case 'FETCH_GRIFFOLS_RUN_RESULTS':
        case 'CLEANUP_GRIFFOLS_RUN_RESULTS':
            return action.payload;
        case 'UPDATE_GRIFFOLS_RUN_RESULT': {
            //TODO: update this
            let index = state?.rows.findIndex((row: ProcessedGriffolsRunResultRow) => {
                return row.uuid === action.payload.uuid
            })
            let newRows: ProcessedGriffolsRunResultRow[] = [...state!.rows];
            let newRow: ProcessedGriffolsRunResultRow = Object.assign({}, newRows[index!], action.payload.data, { isNewRun: false })

            newRows.splice(index!, 1, newRow);
            let nextState: ProcessedGriffolsRunResultResponse = Object.assign({}, state!, {
                rows: newRows
            });

            return nextState;
        }
        case 'ADD_GRIFFOLS_RUN_RESULT': {
            //insert at the correct index (model entries are sorted in descending order by date criteria)
            let newRows = [
                ...state!.rows.slice(0, action.payload.addAfterIndex + 1),
                Object.assign({}, action.payload.newRunResult, { isNewRun: true }),
                ...state!.rows.slice(action.payload.addAfterIndex + 1)
            ];

            let nextState: ProcessedGriffolsRunResultResponse = Object.assign({}, state!, {
                rows: newRows
            });

            return nextState;
        }

        case 'REMOVE_GRIFFOLS_RUN_RESULT': {
            let removedRowDate = state?.rows[action.payload.removeAtIndex].date
            let newRows = [
                ...state!.rows.slice(0, action.payload.removeAtIndex),
                ...state!.rows.slice(action.payload.removeAtIndex + 1)
            ]

            //update (decrease count) run number values for all next runs
            for (let i = action.payload.removeAtIndex; i <= newRows.length; i++) {
                let currentRow = newRows[i];
                if (currentRow.date === removedRowDate) {
                    currentRow.run -= 1;
                } else {
                    break;
                }
            }

            let nextState: ProcessedGriffolsRunResultResponse = Object.assign({}, state!, {
                rows: newRows
            });

            return nextState;
        }
        default:
            return state;
    }
};
