import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { ApiService } from '../core/api-service';

export interface RunResultsInfoResponse {
    customer_No: string;
    item_No: string;
    marker_Name: string;
    method_Name: string;
}

interface FetchRunResultsInfo {
    type: 'FETCH_RUN_RESULTS_INFO';
    payload: RunResultsInfoResponse;
}
interface CleanupRunResultsInfo {
    type: 'CLEANUP_RUN_RESULTS_INFO';
    payload: null;
}

export const runResultsInfoActionCreators = {
    fetchRunResultsInfo:
        (
            customerId: number,
            itemNo: string,
            markerName: string
        ): AppThunkAction<FetchRunResultsInfo> =>
        async (dispatch) => {
            const apiService = new ApiService();
            const response = await apiService.get(
                `protected/runresult/run-result-header-info?productNo=${itemNo}&markerName=${markerName}`
            );

            dispatch({
                type: 'FETCH_RUN_RESULTS_INFO',
                payload: response || null,
            });
        },
    cleanupRunResultsInfo: (): CleanupRunResultsInfo => {
        return {
            type: 'CLEANUP_RUN_RESULTS_INFO',
            payload: null,
        };
    }
};

export const reducer: Reducer<RunResultsInfoResponse | null> = (
    state: RunResultsInfoResponse | null = null,
    incomingAction: Action
): RunResultsInfoResponse | null => {
    const action = incomingAction as FetchRunResultsInfo | CleanupRunResultsInfo;

    switch (action.type) {
        case 'FETCH_RUN_RESULTS_INFO':
        case 'CLEANUP_RUN_RESULTS_INFO':
            return action.payload;
        default:
            return state;
    }
};
