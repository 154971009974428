import * as React from 'react';
import { Col, Container, Row } from 'reactstrap';
import authService from '../authorization/AuthorizeService';
import Layout from '../Layout/Layout';

const ReportConfirmation = (props: any) => {
    return (
        <Layout contentFluid={true}>
            <Container>
                <h4 className="h4 fw-normal form-title">
                    DataQ Analysis &amp; Statistics
                </h4>

                <Row>
                    <Col>
                        <p>Dear customer,</p>
                        <br />
                        <p>
                            We received your request of a statistical report and are currently processing your data. 
                            It will be sent to your e-mail address within a few minutes.
                        </p>
                        <br />
                        <p>
                            If you did not receive your report:
                            <ul>
                                <li>check your spam folder.</li>
                                <li>
                                    check if your e-mail address is correctly registered in this portal: {authService.getUser()?.username}
                                </li>
                            </ul>
                        </p>
                        <br />
                        <p>If it has not arrived in five minutes, please send a message to: <a href="mailto: dataqanalytics@bioqcontrol.com">dataqanalytics@bioqcontrol.com</a></p>
                        <br />
                        <br />
                        <p>With kind regards,</p>
                        <p>The DataQ Analytics team</p>
                        <br />
                        <br />
                        Click <a href="/">here</a> to return to the portal
                    </Col>
                </Row>
            </Container>
        </Layout>
    );
};

export default ReportConfirmation;
