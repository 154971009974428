import * as React from 'react';
import { UncontrolledAccordion } from 'reactstrap';
import Layout from '../Layout/Layout';
import { actionCreators } from "../../store/Products";
import { ApplicationState } from '../../store';
import { connect } from "react-redux";
import createProductsListWithMarkers from './utils/createProductsAndMarkers';
import "./DataEntry.css";
import { useLoading } from '../utilitary-components/LoadingContext';

const SERA_Q_TEST_GROUP_FILTER = "Serology";

const SeraQ = (props: any) => {
  React.useEffect(() => {
    props.fetchProducts();
  }, [])
  const { showLoading, hideLoading } = useLoading();

  const displayLoading = () => {
    showLoading();
    return <></>
  }

  const displayContent = () => {
    hideLoading();
    return <UncontrolledAccordion open="">
      {createProductsListWithMarkers(props.products, "seraq")}
    </UncontrolledAccordion>
  }

  const displayEmptyContent = () => {
    hideLoading();
    return <div>No valid products found</div>
  }

  return (
    <Layout>
      {!props.products && displayLoading()}
      {props.products && !!props.products.length && displayContent()}
      {(props.products && !props.products.length) && displayEmptyContent()}
    </Layout>
  );
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    products: state.products && state.products.length ? state.products.filter(product => product.method_test_group === SERA_Q_TEST_GROUP_FILTER) : null
  };
};

const { fetchProducts } = actionCreators;

export default connect(mapStateToProps, { fetchProducts })(SeraQ);