import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
    Card,
    ListGroup,
    CardHeader,
    ListGroupItem,
    Row,
    Col,
} from 'reactstrap';
import Layout from './Layout/Layout';
import Link from './utilitary-components/Link';
import './Home.css';
import { actionCreators } from '../store/UserStore';

const Home = (props: any) => {
    useEffect(() => {
        props.fetchUserData();
    }, []);

    return (
        <Layout>
            <div className="homepage-wrapper">
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>DataQ Result Entry - SeraQ Controls</CardHeader>
                            <ListGroup variant="flush">
                                <ListGroupItem>
                                    <Link
                                        href="/data-entry/serology-lab-equipment"
                                        className="item"
                                    >
                                        Serology Lab Equipment
                                    </Link>
                                </ListGroupItem>
                                <ListGroupItem>
                                    <Link
                                        href="/data-entry/seraq"
                                        className="item"
                                    >
                                        SeraQ Products
                                    </Link>
                                </ListGroupItem>
                            </ListGroup>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <CardHeader>
                                DataQ Analysis &amp; Statistics - SeraQ Controls
                            </CardHeader>
                            <ListGroup variant="flush">
                                <ListGroupItem>
                                    <Link
                                        href="/generate-statistics/seraq-report"
                                        className="item"
                                    >
                                        SeraQ Reports
                                    </Link>
                                </ListGroupItem>
                            </ListGroup>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>DataQ Result Entry - ViraQ Controls</CardHeader>
                            <ListGroup variant="flush">
                                <ListGroupItem>
                                    <Link
                                        href="/data-entry/nat-lab-equipment"
                                        className="item"
                                    >
                                        NAT Lab Equipment
                                    </Link>
                                </ListGroupItem>
                                <ListGroupItem>
                                    <Link
                                        href="/data-entry/viraq"
                                        className="item"
                                    >
                                        ViraQ Products
                                    </Link>
                                </ListGroupItem>
                            </ListGroup>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <CardHeader>
                                DataQ Analysis &amp; Statistics - ViraQ Controls
                            </CardHeader>
                            <ListGroup variant="flush">
                                <ListGroupItem>
                                    <Link
                                        href="/generate-statistics/viraq-report"
                                        className="item"
                                    >
                                        ViraQ Reports
                                    </Link>
                                </ListGroupItem>
                            </ListGroup>
                        </Card>
                    </Col>
                </Row>
            </div>
            <div className='homepage-footer'>
                <Link
                    href="/privacy-policy"
                >
                    Privacy Policy
                </Link>
            </div>

        </Layout>
    );
};

//TODO: only Home component fetches user data,
// so refresh will not set the current customer name in the Layout component
// on other components

// must fetchUserData somewhere else, in Layout component probably
const { fetchUserData } = actionCreators;

export default connect(
    () => {
        return {};
    },
    { fetchUserData }
)(Home);
