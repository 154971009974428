import { CellDataConfig } from '../custom-cells/cellUtils';

export enum TABLE_TYPE {
    ROCHE,
    GRIFOLS,
    SERAQ
};

const MINIMUM_ROW_HEIGHT = 70;
const CELL_VERTICAL_PADDING = 26;
const EDIT_MODE_SECTION_SPACING = 10;

const BATCH_SECTION_HEIGHT = 40;
const RESULTS_SECTION_HEIGHT = Object.freeze({
    [TABLE_TYPE.GRIFOLS]: 40, //2 rows * 20
    [TABLE_TYPE.ROCHE]: 20, //1 rows * 20
    [TABLE_TYPE.SERAQ]: 20
});
const SPECIFICITY_SECTION_HEIGHT = Object.freeze({
    [TABLE_TYPE.GRIFOLS]: 120, //6 rows * 25
    [TABLE_TYPE.ROCHE]: 100, //5 rows * 25
    [TABLE_TYPE.SERAQ]: 100
});

const BATCH_SECTION_HEIGHT_EDIT = 175;
const RESULTS_SECTION_HEIGHT_EDIT = 90;
const SPECIFICITY_SECTION_HEIGHT_EDIT =  Object.freeze({
    [TABLE_TYPE.GRIFOLS]: 210, //6 rows * 35
    [TABLE_TYPE.ROCHE]: 175, //5 rows * 35
    [TABLE_TYPE.SERAQ]: 175
});

const SINGLE_ROW_HEIGHT = 20;


export const STANDARD_CELL_WIDTH = 50;
export type TableDimensionsData = {
    editModeRowHeight: number;
    viewModeRowHeight: number;
};
export const getTableDimensions = (
    tableType: TABLE_TYPE,
    cellDataConfig: CellDataConfig,
    emptyRowsCount: number = 0
): TableDimensionsData => {
    let numberOfVisibleSections = Object.values(cellDataConfig).filter(
        (val) => val
    ).length;

    let rowHeight =
        0 +
        (cellDataConfig.isBatchnumberSectionVisible
            ? BATCH_SECTION_HEIGHT
            : 0) +
        (cellDataConfig.isResultsSectionVisible ? RESULTS_SECTION_HEIGHT[tableType] : 0) +
        (cellDataConfig.isSamplefieldsSectionVisible
            ? SPECIFICITY_SECTION_HEIGHT[tableType]
            : 0) +
        emptyRowsCount * SINGLE_ROW_HEIGHT;
    rowHeight = Math.max(rowHeight, MINIMUM_ROW_HEIGHT) + CELL_VERTICAL_PADDING;

    let editModeRowHeight =
        0 +
        (cellDataConfig.isBatchnumberSectionVisible
            ? BATCH_SECTION_HEIGHT_EDIT
            : 0) +
        (cellDataConfig.isResultsSectionVisible
            ? RESULTS_SECTION_HEIGHT_EDIT
            : 0) +
        (cellDataConfig.isSamplefieldsSectionVisible
            ? SPECIFICITY_SECTION_HEIGHT_EDIT[tableType]
            : 0) +
        emptyRowsCount * SINGLE_ROW_HEIGHT;
    editModeRowHeight =
        Math.max(editModeRowHeight, MINIMUM_ROW_HEIGHT) +
        Math.max(numberOfVisibleSections - 1, 0) * EDIT_MODE_SECTION_SPACING +
        CELL_VERTICAL_PADDING;

    return {
        editModeRowHeight: editModeRowHeight,
        viewModeRowHeight: rowHeight,
    };
};
