import * as Authentication from './Authentication';
import * as LabEquipment from './LabEquipment';
import * as Products from './Products';
import * as UserStore from './UserStore';
import * as ActiveUserStore from './ActiveUserStore';
import * as RunResults from './RunResults';
import * as RunResultsInfo from './RunResultsInfo';
import * as RunResultsRoche from './RunResultsRoche';
import * as RunResultsGriffols from "./RunResultsGriffols";

// The top-level state object
export interface ApplicationState {
    authentication: Authentication.AuthState | undefined;
    labEquipment: LabEquipment.DeviceData[] | undefined;
    userDetails: UserStore.UserData[] | undefined;
    activeUser: UserStore.UserData | undefined;
    products: Products.ProductData[] | undefined;
    runResults: RunResults.ProcessedRunResultResponse | undefined;
    runResultsInfo: RunResultsInfo.RunResultsInfoResponse | undefined;
    runResultsRoche: RunResultsRoche.ProcessedRocheRunResultResponse | undefined;
    runResultsGriffols: RunResultsGriffols.ProcessedGriffolsRunResultResponse | undefined;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    authentication: Authentication.reducer,
    labEquipment: LabEquipment.reducer,
    products: Products.reducer,
    userDetails: UserStore.reducer,
    activeUser: ActiveUserStore.reducer,
    runResults: RunResults.reducer,
    runResultsInfo: RunResultsInfo.reducer,
    runResultsRoche: RunResultsRoche.reducer,
    runResultsGriffols: RunResultsGriffols.reducer,
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (
        dispatch: (action: TAction) => void,
        getState: () => ApplicationState
    ): void;
}
