import { Icon } from '@rsuite/icons';
import * as React from 'react';
import { FaPlus, FaTimes, FaRegSquare, FaRegCheckSquare } from 'react-icons/fa';
import { Button, DatePicker, Table } from 'rsuite';
import { GriffolsMarkerData, GriffolsRunResult } from '../../../store/RunResultsGriffols';
import { fetchManufacturerBatchIds } from '../../run-results/utils/dropdownValueFetchers';
import { COUNTER_OPERATION } from '../utils/types';

export const GriffolsRunDataCell = ({ ...props }) => {
    const [formData, setFormData] = React.useState<GriffolsRunResult | null>(null);
    const [updatesCount, setUpdatesCount] = React.useState(0);
    const [inhouseBatchIds, setInhouseBatchIds] = React.useState<any[]>([]);
    const [manufacturerBatchIds, setManufacurerBatchIds] = React.useState<any[]>([]);
    const [oldLotNoCache, setOldLotNoCache] = React.useState<string | undefined>("");
    const [isNewLotNoInsertionActive, setIsNewLotNoInsertionActive] = React.useState(false);
    const numberFieldIds = ["ctValue", "samplesTested", "initialReactive", "invalidResults", "repeatReactive", "confirmedInfection"];
    const decimalFields = ["ctValue"];
    const markerDependentFields = ["ctValue", "comment", "repeatReactive", "confirmedInfection", "ic", "batchID"];
    const updateViewAndCollectChange = (targetId: string, value: any, markerIndex?: number) => {
        let nextData: GriffolsRunResult;
        if (markerDependentFields.indexOf(targetId) !== -1) {
            nextData = Object.assign(
                {},
                { ...formData },
                {
                    markerData: formData?.markerData.map(
                        (content, i) => i === markerIndex ? {
                            ...content,
                            [targetId]: value
                        } : content
                    )
                }
            ) as GriffolsRunResult;
        } else {
            nextData = Object.assign({}, { ...formData }, { [targetId]: value }) as GriffolsRunResult;
        }
        setFormData(nextData);
        setUpdatesCount(updatesCount + 1);
    }
    const fetchManuBatchIds = async () => {
        let methodNo = props.rowData[props.dataKey].markerData[0]?.methodNo;
        let batchIds = await fetchManufacturerBatchIds(methodNo);
        setManufacurerBatchIds(batchIds);
    }

    const updateField = (event: any) => {
        let value = event.target.value;
        let markerIndex: number = parseInt(event.target.getAttribute("data-marker-index"));
        if (numberFieldIds.includes(event.target.id)) {
            if (isNaN(value)) {
                return;
            }

            if (decimalFields.includes(event.target.id)) {
                //the unit ratio is 2 decimal places float
                let floatingPart = event.target.value.split(".")[1]
                let decimalPlaces = floatingPart?.length || 0;
                if (decimalPlaces > 2) {
                    return;
                }
                value = parseFloat(event.target.value)
            } else {
                value = parseInt(event.target.value);
            }
        }
       
        updateViewAndCollectChange(event.target.id, value, markerIndex);
    }

    const updateIC = (event: any) => {
        // this is supposed to set values of ic and ctValue together (if ic is checked, ctValue is reset to 0)
        // TODO: extract logic to update marker data into a reusable method that gets the changes object as param
        let markerIndex: number = parseInt(event.target.getAttribute("data-marker-index"));
        if (event.target.id === "ic") {
            let target = event.target as HTMLInputElement;
            let nextData = Object.assign(
                {},
                { ...formData },
                {
                    markerData: formData?.markerData.map(
                        (content, i) => i === markerIndex ? {
                            ...content,
                            ic: target.checked ? 1 : 0,
                            ctValue: target.checked ? 0 : content.ctValue
                        } : content
                    )
                }
            ) as GriffolsRunResult;
    
            setFormData(nextData);
            setUpdatesCount(updatesCount + 1);
        }
    }

    const overrideEmptyNumberFields = (event: any) => {
        if (!event.target.value) {
            let markerIndex: number = parseInt(event.target.getAttribute("data-marker-index"));
            updateViewAndCollectChange(event.target.id, 0, markerIndex)
        }
    }

    React.useEffect(() => {
        if (props.rowData.isInEditMode && props.rowData[props.dataKey]) {
            setFormData(props.rowData[props.dataKey])
            let inhouse = props.getInhouseBatchOptions();
            setInhouseBatchIds(inhouse);
            fetchManuBatchIds();
            setIsNewLotNoInsertionActive(!!props.rowData[props.dataKey].newLotNo);

            if (props.rowData.isNewRun) {
                //COLLECT NEWLY ADDED RUN BLANK DATA ON INIT FOR SAVING PURPOSES
                setUpdatesCount(updatesCount + 1);
            }
        } else if (updatesCount) {
            setFormData(null);
        }

        if (!props.rowData.isInEditMode) {
            setIsNewLotNoInsertionActive(false);
        }
    }, [props.rowData.isInEditMode])

    React.useEffect(() => {
        if (updatesCount && formData) {
            props.collectUpdatedDataForRow(props.rowData.uuid, props.dataKey, { ...formData });
        }
    }, [updatesCount])

    if (!props.rowData[props.dataKey]) {
        return <Table.Cell {...props}></Table.Cell>
    }

    return (
        <Table.Cell
            {...props}
            className={props.rowData.isInEditMode ? 'edit-mode' : ''}
        >
            {props?.cellDataConfig?.isBatchnumberSectionVisible && (
                <div className="run-data-section batchnumbers-wrapper">
                    {!props.rowData.isInEditMode && (
                        <div>
                            {props.rowData[props.dataKey].lotNo ||
                                (props.rowData[props.dataKey].newLotNo ? `${props.rowData[props.dataKey].newLotNo}*` :
                                    '-')}
                        </div>
                    )}

                    {props.rowData.isInEditMode && (
                        <>
                            <div>
                                <select
                                    id="lotNo"
                                    className="table-input lotNo-select form-select form-select-sm"
                                    value={formData?.lotNo}
                                    onChange={updateField}
                                    disabled={isNewLotNoInsertionActive}
                                >
                                    <option disabled selected value="">-</option>
                                    {manufacturerBatchIds.map((option) => (
                                        <option key={option.lotNo} value={option.lotNo}>
                                            {option.lotNo}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className={!!props.rowData.newLotNoInsertionsCount ? 'insert-mode' : ''}>
                                {isNewLotNoInsertionActive && <>
                                    <Button
                                        className="in-cell-button"
                                        appearance="subtle"
                                        size="md"
                                        onClick={() => {
                                            props.toggleNewLotNoInsertionForRow(props.rowData.id, COUNTER_OPERATION.SUBTRACT);
                                            setIsNewLotNoInsertionActive(false);
                                            //cleaning data on editing abort
                                            let cleanData: Partial<GriffolsRunResult> = {
                                                newLotNo: "",
                                                newLotNoExpiryDate: "0001-01-01T00:00:00Z",
                                                lotNo: oldLotNoCache!
                                            };
                                            let nextFormData: GriffolsRunResult = Object.assign({}, { ...formData }, { ...cleanData }) as GriffolsRunResult;
                                            setFormData(nextFormData);
                                            setUpdatesCount(updatesCount + 1);
                                            setOldLotNoCache("");
                                        }}
                                    >
                                        <span>Cancel</span>
                                        <Icon as={FaTimes} />
                                    </Button>
                                    <input
                                        type="text"
                                        className="table-input newLotNo-input form-control form-control-sm"
                                        id="newLotNo"
                                        placeholder="Enter new Lot nr."
                                        value={formData?.newLotNo}
                                        onChange={updateField}
                                    />
                                    <DatePicker
                                        format="dd-MM-yyyy"
                                        size="sm"
                                        className="newLotNoExpiryDate-input custom-date-picker"
                                        id="newLotNoExpiryDate"
                                        cleanable={false}
                                        value={new Date(formData?.newLotNoExpiryDate || new Date())}
                                        onChange={(value) => {
                                            updateViewAndCollectChange("newLotNoExpiryDate", value?.toISOString())
                                        }}
                                    />
                                </>}
                                {!isNewLotNoInsertionActive && <>
                                    <Button
                                        className="in-cell-button"
                                        appearance="subtle"
                                        size="md"
                                        onClick={() => {

                                            if (!props.cachedNewLotNoData) {
                                                let today: Date = new Date();
                                                let expiryDateMinimumLimit: string = today.toISOString().split('.')[0] + "Z";
                                                let nextFormData: GriffolsRunResult = Object.assign({}, { ...formData }, {
                                                    newLotNoExpiryDate: expiryDateMinimumLimit
                                                }) as GriffolsRunResult;
                                                setFormData(nextFormData);
                                                setUpdatesCount(updatesCount + 1);
                                            } else {
                                                let nextFormData: GriffolsRunResult = Object.assign({}, { ...formData }, { ...props.cachedNewLotNoData });
                                                setFormData(nextFormData);
                                                setUpdatesCount(updatesCount + 1);
                                            }
                                            setOldLotNoCache(formData?.lotNo); //cache old value in case we need to restore it later
                                            props.toggleNewLotNoInsertionForRow(props.rowData.id, COUNTER_OPERATION.ADD);
                                            setIsNewLotNoInsertionActive(true);
                                        }}
                                    >
                                        <span>New lot nr.</span>
                                        <Icon as={FaPlus} />
                                    </Button>
                                </>}
                            </div>

                        </>
                    )}
                </div>
            )}
            {props.rowData[props.dataKey].markerData &&
                props.rowData[props.dataKey].markerData.map((markerData: GriffolsMarkerData, index: number) => {
                    return (
                        <div className={"marker-column " + (props.rowData[props.dataKey].markerData.length === 1 ? "single-column" : "") }>
                            {props?.cellDataConfig?.isBatchnumberSectionVisible && (
                                <div className="run-data-section batchnumbers-wrapper">
                                    {!props.rowData.isInEditMode && (
                                        <div>
                                            {props.rowData[props.dataKey].markerData[index].batchID || '-'}
                                        </div>
                                    )}
                                    {props.rowData.isInEditMode && (
                                        <div>
                                            <select
                                                id="batchID"
                                                className="table-input batchID-select form-select form-select-sm"
                                                value={formData?.markerData[index].batchID}
                                                data-marker-index={index}
                                                onChange={updateField}
                                            >
                                                <option disabled selected value="">-</option>
                                                {inhouseBatchIds.filter((ib) => ib.markerName === markerData.markerName && ib.productNo === markerData.itemNo).map((option) => (
                                                    <option key={option.batchID} value={option.batchID}>
                                                        {option.batchID}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    )}
                                </div>
                            )}
                            {(props?.cellDataConfig?.isResultsSectionVisible || props?.cellDataConfig?.isSamplefieldsSectionVisible) && (
                                <>
                                    <div className="subheader-cell">{markerData.itemNo}</div>
                                    <div className="subheader-cell" title={markerData.markerName}>{markerData.markerName}</div>
                                </>
                            )}
                            {props?.cellDataConfig?.isResultsSectionVisible && (
                                <div className="run-data-section results-wrapper">
                                    {!props.rowData.isInEditMode && (
                                        <>
                                            <div>
                                                {props.rowData[props.dataKey].markerData[index].ctValue.toFixed('2')}
                                            </div>
                                            <div className="icon-wrapper">
                                                {Boolean(props.rowData[props.dataKey].markerData[index].ic) ? <Icon as={FaRegCheckSquare} /> : <Icon as={FaRegSquare} />}
                                            </div>
                                        </>
                                    )}
                                    {props.rowData.isInEditMode && (
                                        <div>
                                            <input
                                                type="number"
                                                className="table-input result-input form-control form-control-sm"
                                                id="ctValue"
                                                data-marker-index={index}
                                                value={Boolean(formData?.markerData[index].ic) ? "" : (formData?.markerData[index].ctValue || undefined)}
                                                onChange={updateField}
                                                disabled={Boolean(formData?.markerData[index].ic)}
                                                onBlur={overrideEmptyNumberFields}
                                            />
                                            <div className="checkbox-control-wrapper">
                                                <input
                                                    type="checkbox"
                                                    id="ic"
                                                    data-marker-index={index}
                                                    checked={Boolean(formData?.markerData[index].ic)}
                                                    onClick={updateIC}
                                                />
                                                {(formData?.markerData[index].ic !== 0 && formData?.markerData[index].ic !== 1) && <>
                                                    <span className="ic-error">Wrong value</span>
                                                </>}
                                            </div>
                                            <input
                                                type="text"
                                                className="table-input comment-input form-control form-control-sm"
                                                id="comment"
                                                data-marker-index={index}
                                                value={formData?.markerData[index].comment}
                                                onChange={updateField}
                                            />
                                        </div>
                                    )}
                                </div>
                            )}
                            {props?.cellDataConfig?.isSamplefieldsSectionVisible && (
                                <div className="run-data-section samplefields-wrapper">
                                    {!props.rowData.isInEditMode && ( //TODO Griffols: remove fallback for samplesTested
                                        <>
                                            <div>
                                                {props.rowData[props.dataKey].samplesTested}
                                            </div>
                                            <div>
                                                {props.rowData[props.dataKey].invalidResults}
                                            </div>
                                            <div>
                                                {props.rowData[props.dataKey].initialReactive}
                                            </div>
                                            <div>
                                                {props.rowData[props.dataKey].markerData[index].repeatReactive}
                                            </div>
                                            <div>
                                                {props.rowData[props.dataKey].markerData[index].confirmedInfection}
                                            </div>
                                        </>
                                    )}
                                    {props.rowData.isInEditMode && (
                                        <>

                                            <div className="samplefield-box">
                                                <input
                                                    type="number"
                                                    className="table-input inline-input samples-input form-control form-control-sm"
                                                    id="samplesTested"
                                                    value={formData?.samplesTested || ""}
                                                    disabled={index !== 0}
                                                    onChange={updateField}
                                                    onBlur={overrideEmptyNumberFields}
                                                />
                                            </div>
                                            <div className="samplefield-box">
                                                <input
                                                    type="number"
                                                    className="table-input inline-input invalidResults-input form-control form-control-sm"
                                                    id="invalidResults"
                                                    data-marker-index={index}
                                                    value={formData?.invalidResults || ""}
                                                    disabled={index !== 0}
                                                    onChange={updateField}
                                                    onBlur={overrideEmptyNumberFields}
                                                />
                                            </div>
                                            <div className="samplefield-box">
                                                <input
                                                    type="number"
                                                    className="table-input inline-input initialReactive-input form-control form-control-sm"
                                                    id="initialReactive"
                                                    data-marker-index={index}
                                                    value={formData?.initialReactive || ""}
                                                    disabled={index !== 0}
                                                    onChange={updateField}
                                                    onBlur={overrideEmptyNumberFields}
                                                />
                                            </div>
                                            <div className="samplefield-box">
                                                <input
                                                    type="number"
                                                    className="table-input inline-input repeatReactive-input form-control form-control-sm"
                                                    id="repeatReactive"
                                                    data-marker-index={index}
                                                    value={formData?.markerData[index].repeatReactive || ""}
                                                    onChange={updateField}
                                                    onBlur={overrideEmptyNumberFields}
                                                />
                                            </div>
                                            <div className="samplefield-box">
                                                <input
                                                    type="number"
                                                    className="table-input inline-input confirmedInfection-input form-control form-control-sm"
                                                    id="confirmedInfection"
                                                    data-marker-index={index}
                                                    value={formData?.markerData[index].confirmedInfection || ""}
                                                    onChange={updateField}
                                                    onBlur={overrideEmptyNumberFields}
                                                />
                                            </div>
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                    );
                })
            }
        </Table.Cell>
    )
};