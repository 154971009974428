import React from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { AppRoutes } from '../../core/constants';
import { ApplicationState } from '../../store';
import authService from '../authorization/AuthorizeService';
import banner from "../../assets/images/banner.png";
import logo from "../../assets/images/logo.png";
import './Login.css';

const EmailCodeVerification = (props: any) => {
    let {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const history = useHistory();

    useEffect(() => {
        authService.sendEmailCode();
    }, []);

    const onSubmit = async (formData: any) => {
        const code = formData.code;
        try {
            const jwtToken = await authService.validateEmailCode(code);

            authService.signInSecondStep(jwtToken);
            console.log('Email verification code success');
            history.replace(AppRoutes.CustomerSelect);
        } catch (error) {
            alert('Something went wrong, please try again');
        }
    };

    return (
        <>
            <div className='login-header'>
                <img className="logo" src={logo} />
                <img className="banner" src={banner} />
            </div>
            <div className="login-wrapper">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-title-wrapper">
                        <h1> DataQ Analytics</h1>
                        <h3 className="h3 mb-3 fw-normal">
                            Please verify your e-mail code
                        </h3>
                    </div>

                    <div className="form-row">
                        <label htmlFor="authCode">E-mail verification code</label>
                        <input
                            id="authCode"
                            className="form-control"
                            placeholder="the authenticator validation code"
                            defaultValue={''}
                            {...register('code', { required: true })}
                        />
                        {errors.code && 'Code is required!'}
                    </div>

                    <div className="form-footer">
                        <input
                            className="w-100 btn btn-primary btn-lg"
                            type="submit"
                            value="Submit"
                        />
                        <p className="mt-5 mb-3 text-muted">&copy; 2022</p>
                    </div>
                </form>
            </div>
        </>
    );
};

export default connect(
    (state: ApplicationState) => state.authentication,
    {}
)(EmailCodeVerification as any);
