import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import authService from '../authorization/AuthorizeService';
import { AppRoutes } from '../../core/constants';
import { AuthenticatorValidationRequest } from '../authorization/models/AuthenticatorValidationRequest';
import './Login.css';
import banner from "../../assets/images/banner.png";
import logo from "../../assets/images/logo.png";

const EnrollAuthenticator = (props: any) => {
    let {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const history = useHistory();

    const [barcodeImageUrl, setBarcodeImageUrl] = useState('');
    const [accountSecret, setAccountSecret] = useState('');

    let setupCode: string = '';

    useEffect(() => {
        getAuthenticatorData();
    }, []);

    const getAuthenticatorData = async () => {
        const result = await authService.getAuthenticatorRegistration();
        if (!result.value) {
            throw new Error('Could not read authenticator data from server');
        }

        setBarcodeImageUrl(result.value.barcodeImageUrl);
        setAccountSecret(result.value.accountSecret);
        setupCode = result.value.setupCode || '';
    };

    const onSubmit = async (formData: any) => {
        const model = {
            accountSecret,
            authenticatorValidationCode: formData.authValidationCode
        } as AuthenticatorValidationRequest;
        const success = await authService.validateAuthenticatorEnrollment(
            model
        );
        if (success) {
            alert(
                'Registration success! You will now be redirected to the login page.'
            );
            history.replace(AppRoutes.Login);
        } else {
            alert('Something went wrong, please try again');
        }
    };

    return (
        <>
            <div className='login-header'>
                <img className="logo" src={logo} />
                <img className="banner" src={banner} />
            </div>
            <div className="login-wrapper">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <h1> DataQ Analytics</h1>
                    <h3 className="h3 mb-3 fw-normal">Enroll authenticator in 2 steps</h3>

                    <div className="content">
                        <div>1. Scan the QR code below with your phone</div>
                        <img src={barcodeImageUrl} className="center" />

                        <div className="field">2. Introduce the code you see on the Authenticator app</div>
                        <div className="form-floating">
                            <input
                                className="form-control"
                                placeholder="the authenticator validation code"
                                defaultValue={''}
                                {...register('authValidationCode', {
                                    required: true,
                                })}
                            />
                            {errors.authValidationCode && 'Code is required!'}
                        </div>
                    </div>

                    <input className="w-100 btn btn-primary btn-lg" type="submit" />

                    <p className="mt-5 mb-3 text-muted">&copy; 2022</p>
                </form>
            </div>
        </>
    );
};

export default connect(
    (state: ApplicationState) => state.authentication,
    {}
)(EnrollAuthenticator as any);
