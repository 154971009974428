import React from 'react';
import { Link } from 'react-router-dom';
import authService from '../authorization/AuthorizeService';
import { AppRoutes } from '../../core/constants';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import './NavMenu.css';
import { Nav, Navbar } from 'rsuite';
import logo from "../../assets/images/logo.png";

const NavMenu = (props: any) => {
    const history = useHistory();
    const logOut = (): void => {
        authService.signOut();
        history.replace(AppRoutes.Login);
    };

    const changeCustomer = (): void => {
        history.replace(AppRoutes.CustomerSelect);
    };

    const isSingleUser: boolean = props.userDetails.length === 1;

    return (
        <header>
            <Navbar id="mainMenu" {...props} appearance="subtle">
                <img className="header-logo" src={logo} />
                <Navbar.Brand>
                    <Link className="navbar-brand" to="/">
                        DataQ Analytics
                    </Link>
                </Navbar.Brand>

                <Nav pullRight>
                    <Nav.Menu
                        title="Options"
                        id="optionsToggle"
                        placement="bottomEnd"
                    >
                        {isSingleUser ? null : (
                            <Nav.Item eventKey="1" onClick={changeCustomer}>
                                Change customer
                            </Nav.Item>
                        )}
                        <Nav.Item eventKey="2" onClick={logOut}>
                            Logout
                        </Nav.Item>
                    </Nav.Menu>
                </Nav>
            </Navbar>
        </header>
    );
};

const mapStateToProps = (state: ApplicationState) => {
    return {
        userDetails: state.userDetails,
    };
};

export default connect(mapStateToProps, {})(NavMenu);
