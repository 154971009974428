import React from "react";
import "./PrivacyPolicy.css";
import Layout from "../Layout/Layout";
const PrivacyPolicy = () => {
    return (
        <Layout>
            <div className="policy-body">
                <p className="description">DataQ Analytics has been designed for external quality control of blood screening assays and post-market performance follow-up of in-vitro Diagnostic Medical Devices (IVDs). The website is connected to BioQControl’s MS Nav Dynamics ERP system which includes a database structure for storing performance evaluation data of IVDs and software for generating statistical reports. This login procedure ensures that the performance test results are stored in accordance with applicable law on data protection and is accessible to authorized users only. External data from other sites are grouped together and anonymously presented in statistical reports to enable interlaboratory comparison. </p>
                <p className="contact">Any complaints or feedback concerning the data integrity or functionality of the DataQ Analytics system should be directed to <a href="mailto: dataqanalytics@bioqcontrol.com">dataqanalytics@bioqcontrol.com</a></p>
                <p className="signature">The DataQ Analytics service team</p>
            </div>

        </Layout>
    );
}

export default PrivacyPolicy;