import * as React from 'react';
import { Route } from 'react-router';
import Home from './components/Home';
import './custom.css';
import Login from './components/login/Login';
import ProtectedRoute from './components/authorization/ProtectedRoute';
import { AppRoutes } from './core/constants';
import SecondStepProtectedRoute from './components/authorization/SecondStepProtectedRoute';
import EnrollAuthenticator from './components/login/EnrollAuthenticator';
import AuthorizeAuthenticator from './components/login/AuthorizeAuthenticator';
import EmailCodeVerification from './components/login/EmailCodeVerification';
import Serology from './components/lab-equipment/Serology';
import Virology from './components/lab-equipment/Virology';
import SeraQ from './components/data-entry/SeraQ';
import authService from './components/authorization/AuthorizeService';
import { useState } from 'react';
import CustomerSelect from './components/login/CustomerSelect';
import RunResults from './components/run-results/RunResults';
import StatsGenerator from './components/stats-generator/StatsGenerator';
import ReportConfirmation from './components/stats-generator/ReportConfirmation';
import { Loader } from 'rsuite';
import ViraQ from './components/data-entry/ViraQ';
import RocheRunResults from './components/run-results/roche/RocheRunResults';
import GriffolsRunResults from './components/run-results/griffols/GriffolsRunResults';
import PrivacyPolicy from './components/utilitary-components/PrivacyPolicy';
import { LoadingProvider } from './components/utilitary-components/LoadingContext';

export default (props: any) => {
    const [loading, setLoading] = useState(true);

    React.useEffect(() => {
        loadAuthenticatedUser();

        // debug stuff
        fetch('meta.json', {
            cache: 'no-store',
        })
            .then((response) => response.json())
            .then((meta) => {
                const currentVersion = localStorage.getItem('appversion');
                console.log('Version: ' + currentVersion);
                console.log('Meta version: ' + meta.version);
            });
    }, []);

    const loadAuthenticatedUser = async () => {
        await authService.initializeAuthenticationState();
        setLoading(false);
    };

    return loading ? (
        <div>
            <Loader />
        </div>
    ) : (
        <LoadingProvider>
            <React.Fragment>
                <Route path={AppRoutes.Login} component={Login} />
                <SecondStepProtectedRoute
                    path={AppRoutes.EnrollAuthenticator}
                    component={EnrollAuthenticator}
                />
                <SecondStepProtectedRoute
                    path={AppRoutes.AuthorizeViaAuthenticator}
                    component={AuthorizeAuthenticator}
                />
                <SecondStepProtectedRoute
                    path={AppRoutes.EmailVerification}
                    component={EmailCodeVerification}
                />
                <ProtectedRoute
                    path="/customer-select"
                    component={CustomerSelect}
                />
                <ProtectedRoute exact path="/" component={Home} />
                <ProtectedRoute
                    path="/data-entry/serology-lab-equipment"
                    component={Serology}
                />
                <ProtectedRoute path="/privacy-policy" component={PrivacyPolicy} />
                <ProtectedRoute exact path="/data-entry/seraq" component={SeraQ} />
                <ProtectedRoute
                    path="/data-entry/nat-lab-equipment"
                    component={Virology}
                />
                <ProtectedRoute exact path="/data-entry/viraq" component={ViraQ} />
                <ProtectedRoute
                    path="/data-entry/seraq/run-results-seraq/:itemNo/:markerName"
                    component={RunResults}
                />
                <ProtectedRoute
                    path="/data-entry/viraq/roche/run-results-roche/:itemNo/"
                    component={RocheRunResults}
                />
                <ProtectedRoute
                    path="/data-entry/viraq/grifols/run-results-grifols/:methodGroup/:itemNo/"
                    component={GriffolsRunResults}
                />
                <ProtectedRoute
                    path="/generate-statistics/seraq-report"
                    component={StatsGenerator}
                />
                <ProtectedRoute
                    path="/report-confirm"
                    component={ReportConfirmation}
                />
                <ProtectedRoute
                    path="/generate-statistics/viraq-report"
                    component={StatsGenerator}
                />
            </React.Fragment>
        </LoadingProvider>

    );
};
