export const sanitizeProductName = (productName: string): string => {
    let labelStartIndex = productName.indexOf("(");
    let labelEndIndex = productName.indexOf(")");
    
    if (labelStartIndex == -1 || labelEndIndex == -1) {
        return productName;
    }

    let productNameWithoutLabel = productName.slice(0, labelStartIndex) + productName.slice(labelEndIndex, productName.length - 1);
    return productNameWithoutLabel;
}