import {
    ProcessedRunResultResponse,
    ProcessedRunResultRow,
} from '../../../store/RunResults';
import { formatDate } from './dateUtils';

export type DailyResultsData = {
    pages: Array<ProcessedRunResultRow[]>;
    pageLabels: string[];
};

const getDailyResults = (
    response: ProcessedRunResultResponse
): DailyResultsData => {
    let recordsPages: Array<ProcessedRunResultRow[]> = [];
    let recordPageLabels: string[] = [];

    if (!response || !response.rows.length) {
        return {
            pages: recordsPages,
            pageLabels: recordPageLabels,
        };
    }

    let currentPage: ProcessedRunResultRow[] = [];
    let currentPageLabel = formatDate(response.rows[0].date);

    for (let i = 0; i < response.rows.length; i++) {
        if (response.rows[i].run === 1 && i !== 0) {
            //we consider the last daily page complete when we reach run #1 in a desceding fashion (first run on the next page)
            recordsPages.unshift(currentPage);
            recordPageLabels.unshift(currentPageLabel);
            currentPageLabel = formatDate(response.rows[i].date);
            currentPage = [];
        }

        currentPage.push(response.rows[i]);

        if (i === response.rows.length - 1) { 
            //at the end of the iteration we push the last page and label into the results set
            recordsPages.unshift(currentPage);
            recordPageLabels.unshift(currentPageLabel);
        }
    }

    return {
        pages: recordsPages,
        pageLabels: recordPageLabels,
    };
};

export default getDailyResults;
