import { UserProfile } from "./models/UserProfile";

const AUTHORIZATION_KEY = "Authorization";
const USER_KEY = "user";
const CUSTOMER_NO_KEY = "customerNo";

export class UserManager {
    getUser = (): UserProfile | null => {
        const value = localStorage.getItem(USER_KEY);
        if (value === null) {
            return null;
        }
        return JSON.parse(value) as UserProfile;
    }

    getAuthorization = (): string => {
        return localStorage.getItem(AUTHORIZATION_KEY) || '';
    }

    setUser = (user: UserProfile): void => localStorage.setItem(USER_KEY, JSON.stringify(user));

    setActiveCustomer = (customer: string): void => {
        localStorage.setItem(CUSTOMER_NO_KEY, customer);
    };

    getActiveCustomer = (): string | null => localStorage.getItem(CUSTOMER_NO_KEY);

    removeUser = (): void => localStorage.removeItem(USER_KEY);

    setAuthorization = (authorization: string): void => localStorage.setItem(AUTHORIZATION_KEY, authorization);

    removeAuthorization = (): void  => localStorage.removeItem(AUTHORIZATION_KEY);
}