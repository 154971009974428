import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { ApiService } from '../core/api-service';

export interface Marker {
    marker_ID_Name: string;
}
export interface ProductData {
    item_No: string;
    item_Name: string;
    method_test_group: string;
    batch_ID: string;
    method_group: string;
    method_No: string;
    markers: Marker[];
}

interface FetchProducts {
    type: 'FETCH_PRODUCTS';
    payload: ProductData[];
}

interface GetProducts {
    type: 'GET_PRODUCTS';
}

interface CleanupProducts {
    type: 'CLEANUP_PRODUCTS';
    payload: null;
}

type KnownAction = FetchProducts | GetProducts | CleanupProducts;

export const actionCreators = {
    fetchProducts:
        (customerId: number): AppThunkAction<FetchProducts> =>
        async (dispatch) => {
            const apiService = new ApiService();
            const response = await apiService.get('protected/products');
            dispatch({
                type: 'FETCH_PRODUCTS',
                payload: response,
            });
        },

    getProducts: (): GetProducts => {
        return {
            type: 'GET_PRODUCTS',
        };
    },

    cleanupProducts: (): CleanupProducts => {
        return {
            type: 'CLEANUP_PRODUCTS',
            payload: null
        }
    }
};

export const reducer: Reducer<ProductData[]> = (
    state: ProductData[] = [],
    incomingAction: Action
): ProductData[] => {
    const action = incomingAction as KnownAction;

    switch (action.type) {
        case 'GET_PRODUCTS':
            return state;
        case 'FETCH_PRODUCTS':
            return action.payload;
        case 'CLEANUP_PRODUCTS':
            return [];
        default:
            return state;
    }
};
