import { Icon } from '@rsuite/icons';
import * as React from 'react';
import { FaPlus, FaTimes } from 'react-icons/fa';
import { Button, DatePicker, Table } from 'rsuite';
import { RunResult } from '../../../store/RunResults';
import { fetchManufacturerBatchIds } from '../../run-results/utils/dropdownValueFetchers';
import { COUNTER_OPERATION } from '../utils/types';

export const RunDataCell = ({ ...props }) => {
    const [formData, setFormData] = React.useState<RunResult | null>(null);
    const [updatesCount, setUpdatesCount] = React.useState(0);
    const [inhouseBatchIds, setInhouseBatchIds] = React.useState<any[]>([]);
    const [manufacturerBatchIds, setManufacurerBatchIds] = React.useState<any[]>([]);
    const [oldLotNoCache, setOldLotNoCache] = React.useState<string | undefined>("");
    const [isNewLotNoInsertionActive, setIsNewLotNoInsertionActive] = React.useState(false);
    const numberFieldIds = ["unitRatio", "samplesTested", "initialReactive", "invalidResults", "repeatReactive", "confirmedInfection"];
    const updateViewAndCollectChange = (targetId: string, value: any) => {
        let nextData: RunResult = Object.assign({}, { ...formData }, { [targetId]: value }) as RunResult;
        setFormData(nextData);
        setUpdatesCount(updatesCount + 1);
    }
    const fetchManuBatchIds = async () => {
        let batchIds = await fetchManufacturerBatchIds(props.rowData[props.dataKey].methodNo);
        setManufacurerBatchIds(batchIds);
    }

    const updateField = (event: any) => {
        let value = event.target.value
        if (numberFieldIds.includes(event.target.id)) {
            if (isNaN(value)) {
                return;
            }

            if (event.target.id === "unitRatio") {
                //the unit ratio is 2 decimal places float
                let floatingPart = event.target.value.split(".")[1]
                let decimalPlaces = floatingPart?.length || 0;
                if (decimalPlaces > 2) {
                    return;
                }
                value = parseFloat(event.target.value)
            } else {
                value = parseInt(event.target.value);
            }
        }
        updateViewAndCollectChange(event.target.id, value);
    }

    const overrideEmptyNumberFields = (event: any) => {
        if (!event.target.value) {
            updateViewAndCollectChange(event.target.id, 0)
        }
    }

    React.useEffect(() => {
        if (props.rowData.isInEditMode && props.rowData[props.dataKey]) {
            setFormData(props.rowData[props.dataKey])
            let inhouse = props.getInhouseBatchOptions();
            setInhouseBatchIds(inhouse);
            fetchManuBatchIds();
            setIsNewLotNoInsertionActive(!!props.rowData[props.dataKey].newLotNo);

            if (props.rowData.isNewRun) {
                //COLLECT NEWLY ADDED RUN BLANK DATA ON INIT FOR SAVING PURPOSES
                setUpdatesCount(updatesCount + 1);
            }
        } else if (updatesCount) {
            setFormData(null);
        }

        if (!props.rowData.isInEditMode) {
            setIsNewLotNoInsertionActive(false);
        }
    }, [props.rowData.isInEditMode])

    React.useEffect(() => {
        if (updatesCount && formData) {
            props.collectUpdatedDataForRow(props.rowData.uuid, props.dataKey, { ...formData, runResultNo: props.rowData[props.dataKey].runResultNo });
        }
    }, [updatesCount])

    if (!props.rowData[props.dataKey]) {
        return <Table.Cell {...props}></Table.Cell>
    }

    return (
        <Table.Cell
            {...props}
            className={props.rowData.isInEditMode ? 'edit-mode' : ''}
        >
            {props?.cellDataConfig?.isBatchnumberSectionVisible && (
                <div className="run-data-section batchnumbers-wrapper">
                    {!props.rowData.isInEditMode && (
                        <>
                            <div>
                                {props.rowData[props.dataKey].lotNo ||
                                    (props.rowData[props.dataKey].newLotNo ? `${props.rowData[props.dataKey].newLotNo}*` :
                                        '-')}
                            </div>
                            <div>
                                {props.rowData[props.dataKey].batchID || '-'}
                            </div>
                        </>
                    )}

                    {props.rowData.isInEditMode && (
                        <>
                            <div>
                                <select
                                    id="lotNo"
                                    className="table-input lotNo-select form-select form-select-sm"
                                    value={formData?.lotNo}
                                    onChange={updateField}
                                    disabled={isNewLotNoInsertionActive}
                                >
                                    <option disabled selected value="">-</option>
                                    {manufacturerBatchIds.map((option) => (
                                        <option key={option.lotNo} value={option.lotNo}>
                                            {option.lotNo}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className={!!props.rowData.newLotNoInsertionsCount ? 'insert-mode' : ''}>
                                {isNewLotNoInsertionActive && <>
                                    <Button
                                        className="in-cell-button"
                                        appearance="subtle"
                                        size="md"
                                        onClick={() => {
                                            props.toggleNewLotNoInsertionForRow(props.rowData.id, COUNTER_OPERATION.SUBTRACT);
                                            setIsNewLotNoInsertionActive(false);
                                            //cleaning data on editing abort
                                            let cleanData: Partial<RunResult> = {
                                                newLotNo: "",
                                                newLotNoExpiryDate: "0001-01-01T00:00:00Z",
                                                lotNo: oldLotNoCache
                                            };
                                            let nextFormData: RunResult = Object.assign({}, { ...formData }, { ...cleanData }) as RunResult;
                                            setFormData(nextFormData);
                                            setUpdatesCount(updatesCount + 1);
                                            setOldLotNoCache("");
                                        }}
                                    >
                                        <span>Cancel</span>
                                        <Icon as={FaTimes} />
                                    </Button>
                                    <input
                                        type="text"
                                        className="table-input newLotNo-input form-control form-control-sm"
                                        id="newLotNo"
                                        value={formData?.newLotNo}
                                        onChange={updateField}
                                    />
                                    <DatePicker
                                        format="dd-MM-yyyy"
                                        size="sm"
                                        className="newLotNoExpiryDate-input custom-date-picker"
                                        id="newLotNoExpiryDate"
                                        cleanable={false}
                                        value={new Date(formData?.newLotNoExpiryDate || new Date())}
                                        onChange={(value) => {
                                            updateViewAndCollectChange("newLotNoExpiryDate", value?.toISOString())
                                        }}
                                    />
                                </>}
                                {!isNewLotNoInsertionActive && <>
                                    <Button
                                        className="in-cell-button"
                                        appearance="subtle"
                                        size="md"
                                        onClick={() => {

                                            if (!props.cachedNewLotNoData) {
                                                let today: Date = new Date();
                                                let expiryDateMinimumLimit: string = today.toISOString().split('.')[0]+"Z";
                                                let nextFormData: RunResult = Object.assign({}, { ...formData }, { newLotNoExpiryDate: expiryDateMinimumLimit }) as RunResult;
                                                setFormData(nextFormData);
                                                setUpdatesCount(updatesCount + 1);
                                            } else {
                                                let nextFormData: RunResult = Object.assign({}, { ...formData }, { ...props.cachedNewLotNoData });
                                                setFormData(nextFormData);
                                                setUpdatesCount(updatesCount + 1);
                                            }
                                            setOldLotNoCache(formData?.lotNo); //cache old value in case we need to restore it later
                                            props.toggleNewLotNoInsertionForRow(props.rowData.id, COUNTER_OPERATION.ADD);
                                            setIsNewLotNoInsertionActive(true);
                                        }}
                                    >
                                        <span>New lot nr.</span>
                                        <Icon as={FaPlus} />
                                    </Button>
                                </>}
                            </div>
                            <div>
                                <select
                                    id="batchID"
                                    className="table-input batchID-select form-select form-select-sm"
                                    value={formData?.batchID}
                                    onChange={updateField}
                                >
                                    <option disabled selected value="">-</option>
                                    {inhouseBatchIds.map((option) => (
                                        <option key={option.batchID} value={option.batchID}>
                                            {option.batchID}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </>
                    )}
                </div>
            )}
            {props?.cellDataConfig?.isResultsSectionVisible && (
                <div className="run-data-section results-wrapper">
                    {!props.rowData.isInEditMode && (
                        <div>
                            {props.rowData[props.dataKey].unitRatio.toFixed('2')}
                        </div>
                    )}
                    {props.rowData.isInEditMode && (
                        <div>
                            <input
                                type="number"
                                className="table-input result-input form-control form-control-sm"
                                id="unitRatio"
                                value={formData?.unitRatio ? formData?.unitRatio : undefined}
                                onChange={updateField}
                                onBlur={overrideEmptyNumberFields}
                            />
                            <input
                                type="text"
                                className="table-input comment-input form-control form-control-sm"
                                id="comment"
                                value={formData?.comment}
                                onChange={updateField}
                            />
                        </div>
                    )}
                </div>
            )}

            {props?.cellDataConfig?.isSamplefieldsSectionVisible && (
                <div className="run-data-section samplefields-wrapper">
                    {!props.rowData.isInEditMode && (
                        <>
                            <div>
                                {props.rowData[props.dataKey].samplesTested}
                            </div>
                            <div>
                                {props.rowData[props.dataKey].invalidResults}
                            </div>
                            <div>
                                {props.rowData[props.dataKey].initialReactive}
                            </div>
                            <div>
                                {props.rowData[props.dataKey].repeatReactive}
                            </div>
                            <div>
                                {props.rowData[props.dataKey].confirmedInfection}
                            </div>
                        </>
                    )}
                    {props.rowData.isInEditMode && (
                        <>
                            <div className="samplefield-box">
                                <input
                                    type="number"
                                    className="table-input inline-input samples-input form-control form-control-sm"
                                    id="samplesTested"
                                    value={formData?.samplesTested ? formData?.samplesTested : undefined}
                                    onChange={updateField}
                                    onBlur={overrideEmptyNumberFields}
                                />
                            </div>
                            <div className="samplefield-box">
                                <input
                                    type="number"
                                    className="table-input inline-input invalidResults-input form-control form-control-sm"
                                    id="invalidResults"
                                    value={formData?.invalidResults ? formData?.invalidResults : undefined}
                                    onChange={updateField}
                                    onBlur={overrideEmptyNumberFields}
                                />
                            </div>
                            <div className="samplefield-box">
                                <input
                                    type="number"
                                    className="table-input inline-input initialReactive-input form-control form-control-sm"
                                    id="initialReactive"
                                    value={formData?.initialReactive ? formData?.initialReactive : undefined}
                                    onChange={updateField}
                                    onBlur={overrideEmptyNumberFields}
                                />
                            </div>
                            <div className="samplefield-box">
                                <input
                                    type="number"
                                    className="table-input inline-input repeatReactive-input form-control form-control-sm"
                                    id="repeatReactive"
                                    value={formData?.repeatReactive ? formData?.repeatReactive : undefined}
                                    onChange={updateField}
                                    onBlur={overrideEmptyNumberFields}
                                />
                            </div>
                            <div className="samplefield-box">
                                <input
                                    type="number"
                                    className="table-input inline-input confirmedInfection-input form-control form-control-sm"
                                    id="confirmedInfection"
                                    value={formData?.confirmedInfection ? formData?.confirmedInfection : undefined}
                                    onChange={updateField}
                                    onBlur={overrideEmptyNumberFields}
                                />
                            </div>
                        </>
                    )}
                </div>
            )}
        </Table.Cell>
    )
};