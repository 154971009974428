import * as React from 'react';
import Layout from '../Layout/Layout';
import {
    runResultsActionCreators,
    ProcessedRunResultRow,
    RunResult,
} from '../../store/RunResults';
import { ApplicationState } from '../../store';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import './RunResults.css';
import { runResultsInfoActionCreators } from '../../store/RunResultsInfo';
import { actionCreators as productsActionCreators } from '../../store/Products';
import getWeeklyResults, { WeeklyResultsData } from './utils/getWeeklyResults';
import createTable, { TableRowData } from './utils/createTable';
import createPagination from './utils/createPagination';
import getDailyResults, { DailyResultsData } from './utils/getDailyResults';
import { Button, ButtonGroup, ButtonToolbar, Nav } from 'rsuite';
import { Icon } from '@rsuite/icons';
import { FaEye } from 'react-icons/fa';
import {
    composeRowId,
    decypherRowId,
    RowIdentificationData,
    TABLE_PREFIXES,
} from './utils/rowIdentificationUtils';
import { CellDataConfig } from './custom-cells/cellUtils';
import { ProductData } from '../../store/Products';
import { fetchInhouseBatchIds } from './utils/dropdownValueFetchers';
import { ApiService } from '../../core/api-service';
import { COUNTER_OPERATION } from './utils/types';
import { sanitizeProductName } from '../data-entry/utils/sanitizeProductName';
import { CachedLotNoData } from '../../store/run-result-common-types';
import { useLoading } from '../utilitary-components/LoadingContext';

const VIEW_MODES = {
    DAY: 'daily',
    WEEK: 'weekly',
};

const convertToTableRowModel = (
    pagesData: Array<ProcessedRunResultRow[]>,
    tablePrefix: string,
    newRunReferenceId: string | null,
    expandedRowKeys: string[]
): Array<TableRowData[]> => {
    let results: Array<TableRowData[]> = [];
    //TODO: replace this with a separate simpler state (map uuid to isEditModeActive state)
    pagesData.forEach((page: ProcessedRunResultRow[], pageIndex: number) => {
        let crtPageResults: TableRowData[] = [];
        let currentRunDate: string = page[0].date;

        page.forEach((result: ProcessedRunResultRow, rowIndex: number) => {
            crtPageResults.push({
                ...result,
                isInEditMode: getEditModeStatusForRow(newRunReferenceId, result.uuid, expandedRowKeys),
                isLastRunOfTheDay: rowIndex === page.length - 1,
                newLotNoInsertionsCount: countNewLotNoInsertionsForRow(result),
                id: composeRowId(tablePrefix, pageIndex, rowIndex),
            } as TableRowData);

            if (currentRunDate !== result.date) {
                crtPageResults[rowIndex - 1].isLastRunOfTheDay = true;
                currentRunDate = result.date;
            }
        });

        results.push(crtPageResults);
    });

    return results;
};

const getEditModeStatusForRow = (newRunReferenceId: string | null, currentRowUuid: string, expandedRowKeys: string[]): boolean => {
    if (newRunReferenceId && newRunReferenceId === currentRowUuid) {
        return true;
    }

    if (expandedRowKeys.indexOf(currentRowUuid) !== -1) {
        return true;
    }

    return false;
}

const countNewLotNoInsertionsForRow = (result: ProcessedRunResultRow): number => {
    let columnsWithNewLotNoCount = 0;

    for (let key in result) {
        if (result[key] instanceof Object) {
            if ((result[key] as RunResult).newLotNo) {
                columnsWithNewLotNoCount += 1;
            }
        }
    }

    return columnsWithNewLotNoCount;
}

const RunResults = (props: any) => {
    const { showLoading, hideLoading } = useLoading();
    const [isSavingData, setIsSavingData] = React.useState<boolean>(false);
    const [weeklyResultPages, setWeeklyResultPages] = React.useState<
        Array<TableRowData[]>
    >([]);
    const [dailyResultPages, setDailyResultPages] = React.useState<
        Array<TableRowData[]>
    >([]);
    const [weeklyPageLabels, setWeeklyPageLabels] = React.useState<string[]>(
        []
    );
    const [dailyPageLabels, setDailyPageLabels] = React.useState<string[]>([]);
    const [cachedNewLotNoData, setCachedNewLotNoData] = React.useState<CachedLotNoData | null>(null);
    const [weekPageIndex, setWeekPageIndex] = React.useState<number | null>(null);
    const [dayPageIndex, setDayPageIndex] = React.useState<number | null>(null);
    const [viewMode, setViewMode] = React.useState(VIEW_MODES.DAY);
    const [cellDataConfig, setCellDataConfig] = React.useState<CellDataConfig>({
        isBatchnumberSectionVisible: true,
        isResultsSectionVisible: true,
        isSamplefieldsSectionVisible: true,
    });
    const [expandedRowKeys, setExpandedRowKeys] = React.useState<any[]>([]);
    const rowKey: string = 'uuid';
    const [editedRowValues, setEditedRowValues] = React.useState<{ [uuid: string]: { [dataKey: string]: any } }>({});
    const [inhouseBatchIds, setInhouseBatchIds] = React.useState<any[] | null>(null);
    const [newRunReferenceId, setNewRunReferenceId] = React.useState<string | null>(null);
    const [activeProductName, setActiveProductName] = React.useState<string | null>(null);
    const COUNTER_OPERATION_CALLBACKS = {
        [COUNTER_OPERATION.ADD]: (data: TableRowData): void => {
            data.newLotNoInsertionsCount += 1;
        },
        [COUNTER_OPERATION.SUBTRACT]: (data: TableRowData): void => {
            data.newLotNoInsertionsCount -= 1;
        },
        [COUNTER_OPERATION.RECALCULATE]: (data: TableRowData): void => {
            data.newLotNoInsertionsCount = countNewLotNoInsertionsForRow(data);
        },
    };

    const handleExpanded = (rowUuid: string) => {
        let open = false;
        const nextExpandedRowKeys = [];

        expandedRowKeys.forEach((key) => {
            if (key === rowUuid) {
                open = true;
            } else {
                nextExpandedRowKeys.push(key);
            }
        });

        if (!open) {
            nextExpandedRowKeys.push(rowUuid);
        }

        setExpandedRowKeys(nextExpandedRowKeys);
    };

    const updateEditMode = (targetUuid: string, data: Array<TableRowData[]>, isEditModeOn: boolean): void => {
        for (let i = 0; i < data.length; i++) {
            let page: TableRowData[] = data[i];
            for (let i = 0; i < page.length; i++) {
                let row: TableRowData = page[i];
                if (row.uuid === targetUuid) {
                    row.isInEditMode = isEditModeOn;
                }
            }
        }
    }

    const toggleEditModeForRow = async (
        rowUuid: string,
        isEditModeOn: boolean,
        discardRowChanges?: boolean
    ): Promise<any> => {
        const nextDailyData: Array<TableRowData[]> = [...dailyResultPages];
        const nextWeeklyData: Array<TableRowData[]> = [...weeklyResultPages];
        updateEditMode(rowUuid, nextDailyData, isEditModeOn);
        updateEditMode(rowUuid, nextWeeklyData, isEditModeOn);
        setDailyResultPages(nextDailyData);
        setWeeklyResultPages(nextWeeklyData);

        if (!isEditModeOn) {
            if (editedRowValues[rowUuid]) {
                if (!discardRowChanges) {
                    await saveRunResult(rowUuid);
                } else {
                    clearPendingUpdatesForRow(rowUuid);
                }
            }
        }
    };

    const getSaveReadyData = (rowUuid: string): { [dataKey: string]: any } => {
        let processedValues: { [dataKey: string]: any } = Object.assign({}, editedRowValues[rowUuid]);
        for (let equipmentLabel in processedValues) {
            let resultData = processedValues[equipmentLabel];

            if (resultData.newLotNo) {
                //we cleanup the model here before save, otherwise clearing the keys upon new lot number entry/population would be visible in the view (dropdown values would reset)
                resultData.lotNo = "";
            }
        }

        return processedValues;
    };

    const saveRunResult = async (rowUuid: string): Promise<any> => {
        setIsSavingData(true);
        const apiService = new ApiService();
        const saveResult = await apiService.post("protected/RunResult", getSaveReadyData(rowUuid));
        const runResultNumberUpdates: { [key: string]: string } = {};
        let hasEncounteredErrors = false;

        saveResult.data.forEach((item: any) => {
            if (item.errorMessage) {
                alert(item.errorMessage);
                hasEncounteredErrors = true;
                //TODO show error, highlight error field
            } else if (item?.responseObject?.rRnr) {
                runResultNumberUpdates[item?.responseObject?.webId] = item?.responseObject?.rRnr;
            }
        });

        if (!hasEncounteredErrors) {
            updateRunResultNumbers(runResultNumberUpdates, rowUuid);
            props.updateRunResult(rowUuid, editedRowValues[rowUuid]);
            clearPendingUpdatesForRow(rowUuid);
        }

        setIsSavingData(false);
    }

    const updateRunResultNumbers = (runResultNumberUpdates: { [key: string]: string }, rowUuid: string) => {
        setEditedRowValues((prevValues) => {
            let nextValues = Object.assign({}, prevValues);
            let targetedRowData = nextValues[rowUuid];

            for (let key in targetedRowData) {
                let currentRRn = targetedRowData[key].runResultNo;
                if (runResultNumberUpdates[currentRRn]) {
                    targetedRowData[key].runResultNo = runResultNumberUpdates[currentRRn]
                }
            }
            return nextValues;
        });
    }

    const clearPendingUpdatesForRow = (rowUuid: string) => {
        let remainingEditRowValues = Object.assign(editedRowValues);
        delete remainingEditRowValues[rowUuid];
        setEditedRowValues(remainingEditRowValues);
    }

    const setupWeeklyTableData = (props: any): void => {
        let weeklyResults: WeeklyResultsData = getWeeklyResults(
            props.runResults
        );
        setWeeklyResultPages(
            convertToTableRowModel(
                weeklyResults.pages,
                TABLE_PREFIXES.WEEKLY,
                newRunReferenceId,
                expandedRowKeys
            )
        );
        setWeeklyPageLabels(weeklyResults.pageLabels);
        setWeekPageIndex(typeof weekPageIndex === "number" ? weekPageIndex : weeklyResults.pages.length - 1);
    }

    const setupDailyTableData = (props: any): void => {
        let dailyResults: DailyResultsData = getDailyResults(
            props.runResults
        );
        setDailyResultPages(
            convertToTableRowModel(dailyResults.pages, TABLE_PREFIXES.DAILY, newRunReferenceId, expandedRowKeys)
        );
        setDailyPageLabels(dailyResults.pageLabels);
        setDayPageIndex(typeof dayPageIndex === "number" ? dayPageIndex : dailyResults.pages.length - 1);
    }

    const collectUpdatedDataForRow = (uuid: string, dataKey: string, formData: any) => {
        setEditedRowValues((prevValues) => {
            let nextValues = Object.assign({}, prevValues);
            if (!nextValues[uuid]) {
                nextValues[uuid] = {}
            }

            nextValues[uuid][dataKey] = formData;
            return nextValues;
        });

        if (formData.newLotNo && formData.newLotNoExpiryDate) {
            //update lotno data cache
            setCachedNewLotNoData((prevValues) => {
                let nextLotNoValues = Object.assign({}, prevValues);
                nextLotNoValues.newLotNoExpiryDate = formData.newLotNoExpiryDate;
                nextLotNoValues.newLotNo = formData.newLotNo;
                return nextLotNoValues;
            });
        }
    }

    const getInhouseBatchOptions = () => {
        if (inhouseBatchIds) {
            return inhouseBatchIds;
        }

        return []
    }

    const toggleNewLotNoInsertionForRow = (rowId: string, operation: COUNTER_OPERATION): void => {
        let rID: RowIdentificationData = decypherRowId(rowId);
        if (rID.tablePrefix === TABLE_PREFIXES.DAILY) {
            const nextData: Array<TableRowData[]> = Object.assign(
                [],
                dailyResultPages
            );

            COUNTER_OPERATION_CALLBACKS[operation](nextData[rID.pageIndex][rID.rowIndex]);
            setDailyResultPages(nextData);

        } else if (rID.tablePrefix === TABLE_PREFIXES.WEEKLY) {
            const nextData: Array<TableRowData[]> = Object.assign(
                [],
                weeklyResultPages
            );

            COUNTER_OPERATION_CALLBACKS[operation](nextData[rID.pageIndex][rID.rowIndex]);
            setWeeklyResultPages(nextData);
        }
    }

    const addRunAfter = (uuid: string): void => {
        let freshRowUuid = props.addNewRun(uuid);
        setNewRunReferenceId(freshRowUuid);
    }

    const removeRun = (uuid: string): void => {
        props.removeRun(uuid);
        clearPendingUpdatesForRow(uuid);
    }

    // const scrollElementIntoView = (selector: string): void => {
    //     let domNode = document.querySelector(selector);
    //     console.log(domNode, selector);
    //     if (domNode && domNode instanceof HTMLElement) {
    //         domNode.scrollIntoView();
    //     }
    // }

    React.useEffect(() => {
        if (props.runResults) {
            setupWeeklyTableData(props);
            setupDailyTableData(props);
        }
    }, [props.runResults]);

    React.useEffect(() => {
        if (isSavingData) {
            showLoading();
        } else {
            hideLoading();
        }
    }, [isSavingData]);

    React.useEffect(() => {
        let cleanItemNo = props.match.params.itemNo.replace(/\@/gm, '/');
        let cleanMarkerName = props.match.params.markerName.replace(
            /\@/gm,
            '/'
        );
        let customerId = props.activeUser;

        props.fetchRunResultsInfo(customerId, cleanItemNo, cleanMarkerName);
        props.fetchRunResults(customerId, cleanItemNo, cleanMarkerName);

        return () => {
            props.cleanupRunResultsInfo();
            props.cleanupRunResults();

        }
    }, [])

    React.useEffect(() => {
        if (newRunReferenceId) {
            handleExpanded(newRunReferenceId);
            // scrollElementIntoView(`#${newRunReferenceId}`);
            setNewRunReferenceId(null);
        }
    }, [newRunReferenceId])

    React.useEffect(() => {
        if (props.products && props.products.length) {
            let cleanItemNo = props.match.params.itemNo.replace(/\@/gm, '/');
            let activeProduct = props.products.find((product: ProductData) => product.item_No === cleanItemNo);
            //we remove the version parte out of the item number and we call the endpoint with a wildcard (*)
            let itemNoBase: string = activeProduct.item_No.split("/")[0];

            if (activeProduct) {
                setActiveProductName(sanitizeProductName(activeProduct.item_Name));

                if (!inhouseBatchIds) {
                    fetchInhouseBatchIds(itemNoBase, activeProduct.method_group).then((response: any[]) => {
                        setInhouseBatchIds(response); //TODO: type response
                    });
                }
            }
        } else {
            /**
             * after refreshing this page, products will be empty, as the products fetch call is handled by the products page. 
             * so if we do not come from the products page (hence the store is empty) we need to call fetchProducts here because some of the information
             * on this page (dropdown values and such) are requested with information from the currently active product 
             */
            props.fetchProducts();
        }
    }, [props.products])

    const displayRunResults = (equipments: string[]) => {
        if (!equipments) {
            return showLoading();
        } else if (!isSavingData) {
            hideLoading();
        }

        let weeklyTableRows: TableRowData[] = weeklyResultPages[weekPageIndex !== 0 && !weekPageIndex ? weeklyResultPages.length - 1 : weekPageIndex];
        let dailyTableRows: TableRowData[] = dailyResultPages[dayPageIndex !== 0 && !dayPageIndex ? dailyResultPages.length - 1 : dayPageIndex];

        return (
            <>
                <div className="visibility-controls">
                    <ButtonToolbar>
                        <ButtonGroup>
                            <Button
                                className="fields-visibility-toggle"
                                appearance="subtle"
                                size="sm"
                                active={
                                    cellDataConfig.isBatchnumberSectionVisible
                                }
                                onClick={() => {
                                    setCellDataConfig({
                                        ...cellDataConfig,
                                        isBatchnumberSectionVisible:
                                            !cellDataConfig.isBatchnumberSectionVisible,
                                    });
                                }}
                            >
                                <Icon className="eye-icon" as={FaEye} />
                                <span className="text-label">Reagent Lot</span>
                            </Button>
                            <Button
                                className="fields-visibility-toggle"
                                appearance="subtle"
                                size="sm"
                                active={cellDataConfig.isResultsSectionVisible}
                                onClick={() => {
                                    setCellDataConfig({
                                        ...cellDataConfig,
                                        isResultsSectionVisible:
                                            !cellDataConfig.isResultsSectionVisible,
                                    });
                                }}
                            >
                                <Icon className="eye-icon" as={FaEye} />
                                <span className="text-label">Test Results</span>
                            </Button>
                            <Button
                                className="fields-visibility-toggle"
                                appearance="subtle"
                                size="sm"
                                active={
                                    cellDataConfig.isSamplefieldsSectionVisible
                                }
                                onClick={() => {
                                    setCellDataConfig({
                                        ...cellDataConfig,
                                        isSamplefieldsSectionVisible:
                                            !cellDataConfig.isSamplefieldsSectionVisible,
                                    });
                                }}
                            >
                                <Icon className="eye-icon" as={FaEye} />
                                <span className="text-label">Specificity</span>
                            </Button>
                        </ButtonGroup>
                    </ButtonToolbar>
                </div>
                <Nav appearance="tabs">
                    <Nav.Item
                        active={viewMode === VIEW_MODES.DAY}
                        onSelect={() => {
                            setViewMode(VIEW_MODES.DAY);
                        }}
                    >
                        Result entry by day
                    </Nav.Item>
                    <Nav.Item
                        active={viewMode === VIEW_MODES.WEEK}
                        onSelect={() => {
                            setViewMode(VIEW_MODES.WEEK);
                        }}
                    >
                        Result entry by week
                    </Nav.Item>
                </Nav>
                {viewMode === VIEW_MODES.DAY && (
                    <>
                        <Row>
                            <div className="daily-results-table">
                                {createTable(
                                    equipments,
                                    dailyTableRows,
                                    cellDataConfig,
                                    toggleEditModeForRow,
                                    rowKey,
                                    handleExpanded,
                                    expandedRowKeys,
                                    collectUpdatedDataForRow,
                                    getInhouseBatchOptions,
                                    toggleNewLotNoInsertionForRow,
                                    addRunAfter,
                                    removeRun,
                                    cachedNewLotNoData
                                )}
                            </div>
                        </Row>
                        <Row>
                            {createPagination({
                                pageLabels: dailyPageLabels,
                                previousLabel: 'Previous day',
                                nextLabel: 'Next day',
                                activePageIndex: dayPageIndex,
                                setActivePageIndex: setDayPageIndex,
                            })}
                        </Row>
                    </>
                )}

                {viewMode === VIEW_MODES.WEEK && ( //TODO: cleanup console errors (props to send e.g. handleExpanded)
                    <>
                        <Row>
                            <div className="weekly-results-table">
                                {createTable(
                                    equipments,
                                    weeklyTableRows,
                                    cellDataConfig,
                                    toggleEditModeForRow,
                                    rowKey,
                                    handleExpanded,
                                    expandedRowKeys,
                                    collectUpdatedDataForRow,
                                    getInhouseBatchOptions,
                                    toggleNewLotNoInsertionForRow,
                                    addRunAfter,
                                    removeRun,
                                    cachedNewLotNoData
                                )}
                            </div>
                        </Row>
                        <Row>
                            {createPagination({
                                pageLabels: weeklyPageLabels,
                                previousLabel: 'Previous week',
                                nextLabel: 'Next week',
                                activePageIndex: weekPageIndex,
                                setActivePageIndex: setWeekPageIndex,
                            })}
                        </Row>
                    </>
                )}
            </>
        );
    };

    return (
        <Layout contentFluid={true}>
            <Container>
                <Row className="run-results-header">
                    <Col>
                        <span className="header-label">Assay: </span>{' '}
                        <span className="header-data">
                            {' '}
                            {props.runResultsInfo?.method_Name}{' '}
                        </span>
                    </Col>
                    <Col>
                        <span className="header-label">Product: </span>{' '}
                        <span className="header-data">
                            {' '}
                            {/* {props.runResultsInfo?.item_No.split('/')[0]}{' '} */}
                            {activeProductName}
                        </span>
                    </Col>
                    <Col>
                        <span className="header-label">Marker: </span>{' '}
                        <span className="header-data">
                            {' '}
                            {props.runResultsInfo?.marker_Name}{' '}
                        </span>
                    </Col>
                </Row>
            </Container>
            {displayRunResults(props.runResults?.equipments)}
        </Layout>
    );
};

const { fetchRunResults, cleanupRunResults, updateRunResult, addNewRun, removeRun } = runResultsActionCreators;
const { fetchRunResultsInfo, cleanupRunResultsInfo } = runResultsInfoActionCreators;
const { fetchProducts } = productsActionCreators;

const mapStateToProps = (state: ApplicationState) => {
    return {
        activeUser: state.activeUser,
        runResults: state.runResults,
        runResultsInfo: state.runResultsInfo,
        products: state.products
    };
};

export default connect(mapStateToProps, {
    fetchRunResults,
    cleanupRunResults,
    fetchRunResultsInfo,
    cleanupRunResultsInfo,
    updateRunResult,
    addNewRun,
    removeRun,
    fetchProducts
})(RunResults);
