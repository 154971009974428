// Klant = Custiomer (customer of BioQ)
// Labinstument = Device identification
// Robot naam = Instument – product type
// Robot fabrikant naam = IVD Manufacturer

import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { ApiService } from '../core/api-service';

export interface DeviceData {
    Customer_No: string;
    Lab_Instrument_code: string;
    Robot_Name: string;
    Robot_Manufacturer_Name: string;
    Robot_Manufacturer_Code: string;
    Method_group: string;
    Method_Test_Group: string;
    Method_description: string;
}

// const mockSeraQEquipment: DeviceData[] = [
//     {
//         customerId: "qwe",
//         deviceId: "AS1037",
//         deviceType: "Alinity S",
//         manufacturerName: "Abbott Laboratories",
//     }
// ]

interface FetchSeraQEquipment {
    type: 'FETCH_LAB_EQUIPMENT';
    payload: DeviceData[];
}

interface GetSeraQEquipment {
    type: 'GET_LAB_EQUIPMENT';
}

type KnownAction = FetchSeraQEquipment | GetSeraQEquipment;

export const actionCreators = {
    fetchLabEquipment:
        (customerId: number): AppThunkAction<FetchSeraQEquipment> =>
        async (dispatch) => {
            const apiService = new ApiService();

            const response = await apiService.get(
                'protected/customer-equipment'
            );
            dispatch({
                type: 'FETCH_LAB_EQUIPMENT',
                payload: response.value,
            });
        },

    getSeraQEquipment: (): GetSeraQEquipment => {
        return {
            type: 'GET_LAB_EQUIPMENT',
        };
    },
};

export const reducer: Reducer<DeviceData[]> = (
    state: DeviceData[] = [],
    incomingAction: Action
): DeviceData[] => {
    const action = incomingAction as KnownAction;

    switch (action.type) {
        case 'GET_LAB_EQUIPMENT':
            return state;
        case 'FETCH_LAB_EQUIPMENT':
            return action.payload;
        default:
            return state;
    }
};
