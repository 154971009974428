import * as React from 'react';
import { Table } from 'rsuite';

export const LabelsCell = ({ ...props }) => (
    <Table.Cell {...props} className={props.rowData.isInEditMode ? 'labels-cell edit-mode' : 'labels-cell'}>
        {props?.cellDataConfig?.isBatchnumberSectionVisible && (
            <div className="run-data-section batchnumbers-wrapper">
                {!props.rowData.isInEditMode && (
                    <>
                        <span>Lot nr. test reagent</span>
                        <span>Batch-ID run control</span>
                    </>
                )}
                {props.rowData.isInEditMode && (
                    <>
                        <p>Lot nr. test reagent</p>
                        <p className='button-empty-label'>&nbsp;</p>
                        {!!props.rowData.newLotNoInsertionsCount && (
                            <>
                                <p>New lot nr. test reagent</p>
                                <p>New expiry date</p>
                            </>
                        )}
                        <p>Batch-ID run control</p>
                    </>
                )}
            </div>
        )}
        {props?.cellDataConfig?.isResultsSectionVisible && (
            <div className="run-data-section results-wrapper">
                {!props.rowData.isInEditMode && (
                    <span>S/CO ratio</span>
                )}
                {props.rowData.isInEditMode && (
                    <>
                        <p>S/CO ratio</p>
                        <p>Comment</p>
                    </>
                )}
            </div>
        )}
        {props?.cellDataConfig?.isSamplefieldsSectionVisible && (
            <div className="run-data-section samplefields-wrapper">
                {!props.rowData.isInEditMode && (
                    <>
                        <span>Samples tested</span>
                        <span>Samples Invalid</span>
                        <span>Initial reactive</span>
                        <span>Repeat reactive</span>
                        <span>Confirmed positive</span>
                    </>
                )}
                {props.rowData.isInEditMode && (
                    <>
                        <p>Samples tested</p>
                        <p>Samples Invalid</p>
                        <p>Initial reactive</p>
                        <p>Repeat reactive</p>
                        <p>Confirmed positive</p>
                    </>
                )}
            </div>
        )}
    </Table.Cell>
);
