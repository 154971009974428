export const processDate = (dateString: string): string => {
    let recordDate: Date = new Date(dateString);
    let date: number = recordDate.getDate();
    let month: number = recordDate.getMonth();
    let year: number = recordDate.getFullYear();

    return `${date <= 9 ? '0' + date : date}-${month <= 9 ? '0' + month : month
        }-${year}`;
};

export const processDayOfWeek = (dayIndex: number): string => {
    const dayLabels: string[] = [
        'Sun',
        'Mon',
        'Tue',
        'Wed',
        'Thu',
        'Fri',
        'Sat',
    ];
    return dayLabels[dayIndex];
};