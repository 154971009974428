import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { AppRoutes } from '../../core/constants';
import authService from '../authorization/AuthorizeService';
import "./Login.css";
import banner from "../../assets/images/banner.png";
import logo from "../../assets/images/logo.png";

const Login = () => {
    let {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const history = useHistory();
    const [authenticating, setAuthenticating] = useState<boolean>(false);

    const onSubmit = async (data: any) => {
        setAuthenticating(true);
        const response = await authService.signInFirstStep(data);
        if (response.success) {
            if (data.twoFactorMethod === 'authenticator') {
                const responseValue = response.value;
                if (!responseValue) {
                    throw new Error('userProfile cannot be null');
                }
                if (responseValue.authenticatorRegistered) {
                    history.replace(AppRoutes.AuthorizeViaAuthenticator);
                }
                else {
                    history.replace(AppRoutes.EnrollAuthenticator);
                }
            } else {
                history.replace(AppRoutes.EmailVerification);
            }
        } else {
            alert('Authentication failed!');
        }
        setAuthenticating(false);
    };

    return (
        <>
            <div className='login-header'>
                <img className="logo" src={logo} />
                <img className="banner" src={banner} />
            </div>
            <div className="login-wrapper">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-title-wrapper">
                        <h1> DataQ Analytics</h1>
                        <h3 className="h3 mb-3 fw-normal">Please sign in</h3>
                        <p className="info-message">Press <kbd>Ctrl</kbd> + <kbd>F5</kbd> to make sure you're always running the latest version of our software</p>
                    </div>
                    <div className="form-row">
                        <label htmlFor="email">E-mail</label>
                        <input
                            type="email"
                            className="form-control"
                            id="email"
                            placeholder="example@email.com"
                            defaultValue={''}
                            {...register('username', { required: true })}
                        />
                        {errors.username && 'Email address is required'}
                    </div>
                    <div className="form-row">
                        <label htmlFor="floatingPassword">Password</label>
                        <input
                            type="Password"
                            className="form-control"
                            id="floatingPassword"
                            placeholder="Password"
                            defaultValue={''}
                            {...register('password', { required: true })}
                        />
                        {errors.password && 'Please fill in the password'}
                    </div>

                    <div className="form-row">
                        <label htmlFor="twoFA">Two factor authentication method</label>
                        <select id="twoFA" className="form-select" {...register('twoFactorMethod')}>
                            <option value="email">E-mail</option>
                            <option value="authenticator">Authenticator</option>
                        </select>
                    </div>
                    <div className="form-footer">
                        <input className="w-100 btn btn-primary btn-lg" type="submit" value="Login" disabled={authenticating} />
                        <p className="mt-5 mb-3 text-muted">DataQ Analytics &copy; 2024 - version 1.01.001</p>
                    </div>
                </form>
            </div>
        </>
    );
};

export default Login;
