import { ApiService } from '../core/api-service';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import authService from '../components/authorization/AuthorizeService';

export interface UserData {
    no: string;
    name: string;
    email: string;
}

interface FetchUserDetails {
    type: 'FETCH_USER_DETAILS';
    payload: UserData[];
}

export const actionCreators = {
    fetchUserData: (): AppThunkAction<FetchUserDetails> => async (dispatch) => {
        const user = authService.getUser();
        const apiService = new ApiService();
        if (user) {
            const data = await apiService.get(
                `protected/userProfile?username=${user.username}`
            );
            if (data && data.customers) {
                dispatch({
                    type: 'FETCH_USER_DETAILS',
                    payload: data.customers,
                });
            }
        }
    },
};

export const reducer: Reducer<UserData[]> = (
    state: UserData[] = [] as UserData[],
    incomingAction: Action
): UserData[] => {
    switch (incomingAction.type) {
        case 'FETCH_USER_DETAILS':
            const action = incomingAction as FetchUserDetails;
            return action.payload;
        default:
            return state;
    }
};
