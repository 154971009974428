import * as React from 'react';
import { Card, CardHeader, Col, Row } from 'reactstrap';
import { DeviceData } from '../../../store/LabEquipment';
import { LabEquipmentOrderMap } from './labEquipmentDataPreparator';

const createEquipmentList = (groupedLabEquipment: LabEquipmentOrderMap) => {
    const labEquimpentRobotNames: string[] = Object.keys(groupedLabEquipment);

    const equipmentSections = labEquimpentRobotNames.map(
        (robotName: string, index: number) => {
            const devicesByType: DeviceData[] = groupedLabEquipment[robotName];
            const equipmentCards = devicesByType.map(
                (device: DeviceData, index: number) => {
                    return (
                        <Col className="col-md-2" key={index}>
                            <Card className="device-card text-center">
                                <CardHeader>
                                    {device.Lab_Instrument_code}
                                </CardHeader>
                            </Card>
                        </Col>
                    );
                }
            );
            return (
                <Row key={index}>
                    <Row>
                        <Col>
                            <span className="robot-name-label">
                                {robotName}
                            </span>
                        </Col>
                    </Row>
                    <Row>{equipmentCards}</Row>
                </Row>
            );
        }
    );

    const noEquipmentRegisteredState = (
        <Col>
            <p>
                You don't have any equipment registered yet. Please fill in the
                form below to register new equipment
            </p>
        </Col>
    );

    return equipmentSections && equipmentSections.length
        ? equipmentSections
        : noEquipmentRegisteredState;
};

export default createEquipmentList;
