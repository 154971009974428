export const TABLE_PREFIXES = {
    DAILY: 'dys',
    WEEKLY: 'wks',
};

const PREFIXES = {
    PAGE: 'pg',
    ROW: 'rw',
};

const SEPARATOR = '-';

export type RowIdentificationData = {
    tablePrefix: string;
    pageIndex: number;
    rowIndex: number;
};

export const composeRowId = (
    tablePrefix: string,
    pageIndex: number,
    rowIndex: number
): string => {
    return `${tablePrefix}${SEPARATOR}${PREFIXES.PAGE}${pageIndex}${SEPARATOR}${PREFIXES.ROW}${rowIndex}`;
};

export const decypherRowId = (rowId: string): RowIdentificationData => {
    const rowIdentificationData = rowId.split(SEPARATOR);
    return {
        tablePrefix: rowIdentificationData[0],
        pageIndex: Number(rowIdentificationData[1].replace(PREFIXES.PAGE, '')),
        rowIndex: Number(rowIdentificationData[2].replace(PREFIXES.ROW, '')),
    };
};
